import React, { FC, useEffect, useRef, useState } from 'react'
import { EDITABLE_NAME_SYMBOLS_MAX_LENGTH } from '../../app/constants'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { EditIcon } from '../../image_files/icons/EditIcon'
import { getValidText } from '../../utils/helpers/getValidText'
import UiInput from '../ui/input/UiInput'
import './editableSpan.css'
import { IEditableSpanProps } from './types-ALS-101'

export const EditableSpan: FC<IEditableSpanProps> = (props) => {
    const {
        title,
        className,
        children,
        handleChange,
        lengthLimit,
    } = props
    const wrapperRef = useRef(null)

    const [editMode, setEditMode] = useState(false)
    const [errorValue, setErrorValue] = useState('')
    const [inputValue, setInputValue] = useState(title ? title : '')

    const closeEdit = () => {
        setEditMode(false)
        setErrorValue('')
    }

    useOnClickOutside([wrapperRef], closeEdit)

    const HandleKeyDown = (e) => {
        if (e.code === 'Enter' && inputValue.length > 0) {
            closeEdit()
        }
    }
    const handleBlur = () => {
        if (inputValue.length > 0) {
            closeEdit()
        }
        if (inputValue.length === 0) {
            setInputValue(title)
        }
    }

    useEffect(()=>{
        if(editMode===false && inputValue && title && inputValue!==title){
            handleChange && handleChange(inputValue)
            setErrorValue('')
        }
    },[editMode,inputValue,title])

    const handleInputChange = (e) => {
        setErrorValue('')
        setInputValue(e.currentTarget.value)
    }

    useEffect(() => {
        setInputValue(title)
        setErrorValue('')
    }, [title])

    console.log('inputValue', inputValue)

    return (
        <div
            className={`row-flex-5 editableSpan ${getValidText(className)}`}
            ref={wrapperRef}
        >
            {editMode ? (
                <div className="w-100p">
                    <UiInput
                        maxLength={
                            lengthLimit
                                ? lengthLimit
                                : EDITABLE_NAME_SYMBOLS_MAX_LENGTH
                        }
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        onKeyDown={HandleKeyDown}
                    />
                    {errorValue.length > 0 && (
                        <p className={'font-error editableSpan__error'}>
                            {errorValue}
                        </p>
                    )}
                </div>
            ) : (
                <div
                    className={'cursor-pointer transition-2 br-default editableSpan__container'}
                    onDoubleClick={() => setEditMode(true)}
                >
                    <span
                        className="font-14-normal line-heignt-30 editableSpan__text"
                        onDoubleClick={() => setEditMode(true)}
                    >
                        {inputValue}
                    </span>
                    {children ? children : ''}
                    <EditIcon
                        className={`editableSpan__icon`}
                    />
                </div>
            )}
        </div>
    )
}
