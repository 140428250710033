import { asyncActions, slice } from './templates-slice'
import * as tasksSelectors from './selectors'
import * as actions from './actions'

const temlatesActions = {
    ...asyncActions,
    ...slice.actions,
    ...actions,
}

const templatesReducer = slice.reducer

export { templatesReducer, tasksSelectors, temlatesActions }