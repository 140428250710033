import { Radio } from 'antd'
import React, { ReactNode, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { IPopoverMenuItem } from '../../../../app/types/common'
import { ButtonItem } from '../../../../app/types/i-button'
import {
    IPanel,
    SortPanelTasksTypes,
} from '../../../../app/types/models/i-panel'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { DeleteIcon } from '../../../../image_files/icons/DeleteIcon'
import { EditIcon } from '../../../../image_files/icons/EditIcon'
import ReplaceIcon from '../../../../image_files/icons/ReplaceIcon'
import StatusSortIcon from '../../../../image_files/icons/StatusSortIcon'
import ModalInlog from '../../../../shared/modalInlog/ModalInlog'
import PopoverMenu from '../../../../shared/popoverMenu/PopoverMenu'
import UiInput from '../../../../shared/ui/input/UiInput'
import { panelsActions } from '../../../../store/panels'
import { getValidText } from '../../../../utils/helpers/getValidText'
import { showNotify } from '../../../../utils/helpers/showNotice'
import { ITask } from '../../../../app/types/models/tasks'

enum ModalDataTypes {
    STATUS_DELITION = 'status-delition',
    STATUS_EDITING = 'status-editing',
    MOVE_TASKS = 'move-tasks',
    SORT_TASKS = 'sort-tasks',
}

const sortVariants = [
    { type: SortPanelTasksTypes.BY_CREATION_DATE },
    { type: SortPanelTasksTypes.BY_LOW_TO_CRITICAL_PRIORITY },
    { type: SortPanelTasksTypes.BY_CRITICAL_TO_LOW_PRIORITY },
    { type: SortPanelTasksTypes.BY_NAME },
]

const initialModalData = {
    type: ModalDataTypes.STATUS_DELITION as ModalDataTypes,
    open: false,
    title: '',
    iconTitle: undefined as ReactNode | undefined,
    value: '' as any,
}

const PanelMenu = ({
    panel,
    panels,
    tasks,
    panelsOrder,
}: {
    panel: IPanel
    panels: IPanel[]
    panelsOrder: number[]
    tasks: ITask[]
}) => {
    const { t, i18n } = useTranslation()
    const dispatch = useAppDispatch()
    const [closeMenuTrigger, setCloseMenuTrigger] = useState(0)
    const [modalData, setModalData] = useState(initialModalData)
    const [statusPositionSymbol, setStatusPositionSymbol] = useState(false)
    const { addTasksToPanel, updateTasksOrder, moveAllTasks } = panelsActions
    const [moveTasksData, setMoveTasksData] = useState<
        Array<{ id?: number; name?: string; checked: boolean; position: 0 }>
    >([])

    const [sortTasksData, setSortTasksData] = useState<
        Array<{ id?: number; type: SortPanelTasksTypes; checked: boolean }>
    >([])

    const projectId = Number(location.pathname.split('/')[2])

    const menuItems: IPopoverMenuItem[] = [
        {
            id: 1,
            title: t('move-tasks'),
            icon: <ReplaceIcon />,
            onClick: () => {
                setModalData({
                    open: true,
                    iconTitle: <ReplaceIcon />,
                    title: 'tasks-moving',
                    type: ModalDataTypes.MOVE_TASKS,
                    value: '',
                })
            },
        },
        {
            id: 2,
            title: t('sort-tasks'),
            icon: <StatusSortIcon />,
            onClick: () => {
                setModalData({
                    open: true,
                    iconTitle: <StatusSortIcon />,
                    title: 'sorting-tasks',
                    type: ModalDataTypes.SORT_TASKS,
                    value: '',
                })
            },
        },
        {
            id: 3,
            title: t('edit-status'),
            icon: <EditIcon />,
            onClick: () => {
                setModalData({
                    open: true,
                    iconTitle: <EditIcon />,
                    title: 'status-editing',
                    type: ModalDataTypes.STATUS_EDITING,
                    value: panel?.name,
                })
            },
        },
        {
            id: 4,
            title: t('delete-status'),
            icon: <DeleteIcon />,
            onClick: () => {
                setModalData({
                    open: true,
                    iconTitle: <DeleteIcon />,
                    title: 'status-delition',
                    type: ModalDataTypes.STATUS_DELITION,
                    value: '',
                })
            },
        },
    ]

    const getValidMenuITems = () => {
        if (tasks && tasks.length > 0) {
            if (tasks.length === 1) {
                return [menuItems[0], menuItems[2], menuItems[3]]
            } else {
                return menuItems
            }
        } else {
            return [menuItems[2], menuItems[3]]
        }
    }

    const getModalContent = (type: ModalDataTypes) => {
        switch (type) {
            case ModalDataTypes.STATUS_EDITING:
                return (
                    <div>
                        <UiInput
                            value={modalData.value}
                            onChange={(e) => {
                                setModalData((prev) => ({
                                    ...prev,
                                    value: e.target.value,
                                }))
                            }}
                        />
                    </div>
                )
            case ModalDataTypes.STATUS_DELITION:
                return (
                    <div className="font-14-normal">
                        {t('ready-to-delete-status-question')}
                    </div>
                )
            case ModalDataTypes.MOVE_TASKS:
                return (
                    <div className="replace-tasks-wrapper">
                        {showMoveTaskCheckboxes()}
                    </div>
                )
            case ModalDataTypes.SORT_TASKS:
                return (
                    <div className="sort-tasks-wrapper">
                        {showSortTaskCheckboxes()}
                    </div>
                )
            default:
                return <></>
        }
    }

    const getModalButtons = (type: ModalDataTypes): ButtonItem[] => {
        switch (type) {
            case ModalDataTypes.STATUS_EDITING:
                return [
                    {
                        id: 1,
                        titleBtn: t('cancel'),
                        handleBtn: closeModal,
                    },
                    {
                        id: 2,
                        type: 'primary',
                        isDisabled: modalData.value.length === 0,
                        className: 'modal-button-primary-list',
                        titleBtn: t('save'),

                        handleBtn: editStatus,
                    },
                ]
            case ModalDataTypes.STATUS_DELITION:
                return [
                    {
                        id: 1,
                        titleBtn: t('cancel'),
                        handleBtn: closeModal,
                    },
                    {
                        id: 2,
                        type: 'primary',
                        className: 'modal-button-primary-list',
                        titleBtn: t('delete'),
                        handleBtn: deletePanel as (val?: string) => void,
                    },
                ]
            case ModalDataTypes.MOVE_TASKS:
                return [
                    {
                        id: 1,
                        titleBtn: t('cancel'),
                        handleBtn: closeModal,
                    },
                    {
                        id: 2,
                        type: 'primary',
                        className: 'modal-button-primary-list',
                        titleBtn: t('sort'),
                        isDisabled: !moveTasksData.find((el) => el.checked),
                        handleBtn: multipleTasksUpdate,
                    },
                ]
            case ModalDataTypes.SORT_TASKS:
                return [
                    {
                        id: 1,
                        titleBtn: t('cancel'),
                        handleBtn: closeModal,
                    },
                    {
                        id: 2,
                        type: 'primary',
                        className: 'modal-button-primary-list',
                        titleBtn: t('sort'),
                        isDisabled: !sortTasksData.find((el) => el.checked),
                        handleBtn: sortTasks,
                    },
                ]
        }
    }

    const editStatus = () => {
        dispatch(
            panelsActions.changeStatusName({
                panel,
                projectId,
                value: modalData.value,
                onSuccess: () => {
                    closeModal()
                    showNotify(t('name-changed-successfully'))
                },
                onError: () => {
                    closeModal()
                },
            }),
        )
    }

    const deletePanel = () => {
        dispatch(
            panelsActions.removePanel({
                orderList: panelsOrder,
                panel,
                projectId,
                onSuccess: () => {
                    closeModal()
                    showNotify(t('status-deleted-successfully'))
                },
                onError: () => {
                    closeModal()
                },
            }),
        )
    }

    const closeModal = () => {
        setCloseMenuTrigger((prev) => prev + 1)
        setModalData(initialModalData)
    }

    const sortTasks = () => {
        const type = modalData.value
        dispatch(
            panelsActions.updatePanelTasksOrder({
                panel,
                projectId,
                statusPositionSymbol,
                type,
                onSuccess: () => {
                    if (type === SortPanelTasksTypes.BY_CREATION_DATE) {
                        setStatusPositionSymbol((prev) => !prev)
                    }
                    closeModal()
                },
                onError: () => {
                    closeModal()
                },
            }),
        )
    }

    const multipleTasksUpdate = () => {
        dispatch(
            panelsActions.multiplePanelTaskUpdate({
                currentPanel: panel,
                targetPanel: {
                    position: modalData.value?.position,
                    id: modalData?.value?.id,
                },
                projectId,
                tasks,
                onSuccess: () => {
                    closeModal()
                },
                onError: () => {
                    closeModal()
                },
            }),
        )
    }

    const showMoveTaskCheckboxes = () => {
        if (moveTasksData && moveTasksData.length > 0) {
            const result = []
            moveTasksData.forEach((el, i) => {
                result.push(
                    <div
                        key={i}
                        className="font-14-normal p-5 cursor-pointer row-flex-sb item-hover"
                        onClick={() => {
                            setMoveTasksData((prev) =>
                                prev.map((val) => ({
                                    ...val,
                                    checked: val?.id === el?.id,
                                })),
                            )
                            setModalData((prev) => ({
                                ...prev,
                                value: {
                                    id: el?.id,
                                    position: el?.position,
                                },
                            }))
                        }}
                    >
                        <span>{el?.name}</span>
                        <Radio checked={el?.checked} />
                    </div>,
                )
            })

            return <>{result}</>
        } else return <></>
    }

    const showSortTaskCheckboxes = () => {
        if (sortTasksData && sortTasksData.length > 0) {
            const result = []
            sortTasksData.forEach((el, i) => {
                result.push(
                    <div
                        key={i}
                        className="font-14-normal p-5 cursor-pointer row-flex-sb item-hover"
                        onClick={() => {
                            setSortTasksData((prev) =>
                                prev.map((val) => ({
                                    ...val,
                                    checked: val?.id === el?.id,
                                })),
                            )
                            setModalData((prev) => ({
                                ...prev,
                                value: el?.type,
                            }))
                        }}
                    >
                        <span>{t(getValidText(el?.type))}</span>
                        <Radio checked={el?.checked} />
                    </div>,
                )
            })

            return <>{result}</>
        } else return <></>
    }

    useEffect(() => {
        if (panels && Object.keys(panels).length > 0) {
            const list = []
            for (let key in panels) {
                const elem = panels[key]
                list.push({
                    id: elem?.id,
                    position: elem?.position,
                    name: elem?.name,
                    checked: false,
                })
            }
            setMoveTasksData(list)
        }
    }, [panels])

    useEffect(() => {
        setSortTasksData(
            sortVariants.map((el, i) => ({
                id: i,
                checked: false,
                type: el?.type,
            })),
        )
    }, [i18n.language])

    return (
        <>
            <PopoverMenu
                items={getValidMenuITems()}
                closeTrigger={closeMenuTrigger}
                className="roadmap-item-menu"
            />

            {createPortal(
                <ModalInlog
                    width={400}
                    footer={null}
                    {...modalData}
                    title={t(getValidText(modalData?.title))}
                    listButton={getModalButtons(modalData.type)}
                    onCancel={closeModal}
                >
                    {getModalContent(modalData.type)}
                </ModalInlog>,
                document.body,
            )}
        </>
    )
}

export default PanelMenu
