import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    IComment,
    IStatus,
    ISubTask,
    ITag,
    ITask,
    ITaskFieldsForUpdates,
    ITasksFilterParams,
} from '../../app/types/models/tasks'
import {
    addTask,
    deleteTaskAssigneeMember,
    deleteTask,
    setCurrentTask,
    setTaskPriority,
    setTaskTitle,
    setTasks,
    updateTaskAssigneeList,
    updateTaskSupervisor,
    setSubTasks,
    addSubtask,
    deleteSubtask,
    setComments,
    updateTagsList,
    setStatuses,
    updateTask,
    addComment,
    setTaskStatus,
} from './actions'
import { AxiosError } from 'axios'
import { setAppStatus } from '../app/actions'
import { handleAsyncServerNetworkError } from '../../utils/helpers/errors/handleAsyncError'
import { tasksService } from '../../services/tasks-services'
import { changePanelTask } from '../panels/actions'
import { RequestStatusType } from '../../app/types/request'
import { PriorityTypes } from '../../app/types/enums'

export interface InitialState {
    count: number
    next: null | number | string
    previous: null | number | string
    results: ITask[]
    currentTask: null | ITask
    subtasks: ISubTask[]
    comments: IComment[]
    statuses: IStatus[]
    status: RequestStatusType
}

const initialState: InitialState = {
    count: 0,
    next: null,
    previous: null,
    results: [],
    currentTask: null,
    subtasks: [],
    comments: [],
    statuses: [],
    status: 'idle',
}

const getTasks = createAsyncThunk<
    void,
    {
        projectId: number
        params?: ITasksFilterParams
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/getTasksAsync',
    async ({ projectId, params, onSuccess, onError }, thunkAPI: any) => {
        try {
            thunkAPI.dispatch(setTaskStatus('loading'))
            const res = await tasksService.getTasks(projectId, params)
            thunkAPI.dispatch(setTasks(res))
            thunkAPI.dispatch(setTaskStatus('succeeded'))
            onSuccess && onSuccess()
        } catch (e) {
            onError && onError(e)
            thunkAPI.dispatch(setTaskStatus('failed'))
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

const getCurrentTask = createAsyncThunk<
    void,
    {
        slug: string
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/getCurrentTaskDoersAsync',
    async ({ slug, onSuccess, onError }, thunkAPI: any) => {
        try {
            const currentProject = thunkAPI.getState()?.currentProject?.project
            thunkAPI.dispatch(setAppStatus({ status: 'loading' }))
            const res = await tasksService.getCurrentTask(
                currentProject?.id,
                decodeURIComponent(slug),
            )
            thunkAPI.dispatch(setCurrentTask({ currentTask: res }))
            thunkAPI.dispatch(setAppStatus({ status: 'succeeded' }))
            onSuccess && onSuccess()
        } catch (e) {
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

const changeTaskDoers = createAsyncThunk<
    void,
    {
        projectId: number
        task: ITask
        idList: number[]
        panelPosition?: number
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/changeTaskDoersAsync',
    async (
        { idList, task, projectId, panelPosition, onSuccess, onError },
        thunkAPI: any,
    ) => {
        try {
            const members = thunkAPI.getState()?.members?.members ?? []
            const doers =
                task?.doers && task?.doers.length > 0
                    ? task.doers.map((el) => el?.user?.id)
                    : []
            // thunkAPI.dispatch(setAppStatus({ status: 'loading' }))
            const localIds = doers || []
            if (idList?.length > 0 && localIds?.length < idList?.length) {
                //добавляю участника
                const lastEl = idList[idList.length - 1]
                const res = await tasksService.addTaskDoer(
                    projectId,
                    task?.slug,
                    {
                        user: lastEl,
                        project: projectId,
                    },
                )

                const targetDoer = members?.find(
                    (val) => val?.user?.id === lastEl,
                )
                if (targetDoer) {
                    const result = {
                        ...task,
                        doers: [...task?.doers, { ...targetDoer, id: res?.id }],
                    }
                    thunkAPI.dispatch(updateTask(result))
                    if (panelPosition !== undefined) {
                        thunkAPI.dispatch(
                            changePanelTask({
                                position: panelPosition,
                                task: result,
                            }),
                        )
                    }
                }
            } else {
                //удаляю
                if (idList?.length > 0) {
                    const targetDoer = task?.doers?.find(
                        (el) => !idList.includes(el?.user?.id),
                    )
                    await tasksService.deleteTaskAssignee(
                        projectId,
                        task?.slug,
                        targetDoer?.id,
                    )

                    const result = {
                        ...task,
                        doers: task?.doers?.filter(
                            (el) => el?.id !== targetDoer?.id,
                        ),
                    }

                    thunkAPI.dispatch(updateTask(result))
                    if (panelPosition !== undefined) {
                        thunkAPI.dispatch(
                            changePanelTask({
                                position: panelPosition,
                                task: result,
                            }),
                        )
                    }
                } else {
                    const targetDoer = task?.doers[0]
                    if (targetDoer) {
                        await tasksService.deleteTaskAssignee(
                            projectId,
                            task?.slug,
                            targetDoer?.id,
                        )

                        const result = {
                            ...task,
                            doers: [],
                        }

                        thunkAPI.dispatch(updateTask(result))

                        if (panelPosition !== undefined) {
                            thunkAPI.dispatch(
                                changePanelTask({
                                    position: panelPosition,
                                    task: result,
                                }),
                            )
                        }
                    }
                }
            }
            // thunkAPI.dispatch(setAppStatus({ status: 'succeeded' }))
            onSuccess && onSuccess()
        } catch (e) {
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

const changeTaskSupervisor = createAsyncThunk<
    void,
    {
        projectId: number
        task: ITask
        id: number | string
        panelPosition?: number
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/changeTaskSupervisorAsync',
    async (
        { id, task, projectId, panelPosition, onSuccess, onError },
        thunkAPI: any,
    ) => {
        try {
            const supervisor = task?.supervisor
            const members = thunkAPI.getState()?.members?.members ?? []
            if (supervisor?.id !== undefined) {
                //уже есть наблюдатель
                await tasksService.deleteTaskSuperVisor(
                    projectId,
                    task?.slug,
                    task?.supervisor?.id,
                )
            }
            if (id !== undefined) {
                const res = await tasksService.addTaskSuperVisor(
                    projectId,
                    task?.slug,
                    {
                        user: id,
                        project: projectId,
                    },
                )
                const targetMember = members?.find(
                    (el) => el?.user?.id === res?.user,
                )

                if (targetMember) {
                    const result = {
                        ...task,
                        supervisor: { ...targetMember, id: res?.id },
                    }
                    thunkAPI.dispatch(updateTask(result))
                    if (panelPosition !== undefined) {
                        thunkAPI.dispatch(
                            changePanelTask({
                                position: panelPosition,
                                task: result,
                            }),
                        )
                    }
                }
            } else {
                const result = {
                    ...task,
                    supervisor: undefined,
                }
                thunkAPI.dispatch(updateTask(result))
                if (panelPosition !== undefined) {
                    thunkAPI.dispatch(
                        changePanelTask({
                            position: panelPosition,
                            task: result,
                        }),
                    )
                }
            }
            // thunkAPI.dispatch(setAppStatus({ status: 'succeeded' }))
            onSuccess && onSuccess()
        } catch (e) {
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

const changeTaskFields = createAsyncThunk<
    void,
    {
        task: ITask
        data: ITaskFieldsForUpdates
        panelPosition?: number
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/changeTaskFieldsAsync',
    async (
        { task, data, panelPosition, onSuccess, onError },
        thunkAPI: any,
    ) => {
        try {
            const res = await tasksService.updateTask(
                data,
                task?.project,
                task?.slug,
            )
            const result = { ...res, project: task?.project }
            thunkAPI.dispatch(updateTask(result))
            if (panelPosition !== undefined) {
                thunkAPI.dispatch(
                    changePanelTask({
                        position: panelPosition,
                        task: {
                            ...res,
                            status_position: task?.status_position,
                        },
                    }),
                )
            }
            onSuccess && onSuccess(res)
        } catch (e) {
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

const getComments = createAsyncThunk<
    void,
    {
        projectId: number
        slug: string
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/getCommentsAsync',
    async ({ projectId, slug, onSuccess, onError }, thunkAPI: any) => {
        try {
            const res = await tasksService.getTaskComments(projectId, slug)
            thunkAPI.dispatch(setComments({ comments: res }))
            thunkAPI.dispatch(updateTask({ slug, comments: res }))
            onSuccess && onSuccess()
        } catch (e) {
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

const addTaskFiles = createAsyncThunk<
    void,
    {
        projectId: number
        slug: string
        file: any
        panelPosition?: number
        task?: ITask
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/addTaskFilesAsync',
    async (
        { projectId, slug, task, file, panelPosition, onSuccess, onError },
        thunkAPI: any,
    ) => {
        try {
            const fmData = new FormData()
            fmData.append('file', file)
            const res = await tasksService.addTaskFile(projectId, slug, file)
            const tasks = thunkAPI.getState().tasks?.results
            if (tasks && tasks.length > 0) {
                const targetTask = tasks.find((el) => el?.slug === slug)
                if (targetTask) {
                    thunkAPI.dispatch(
                        updateTask({
                            slug,
                            files: (targetTask?.files
                                ? [...targetTask.files, res]
                                : [file]) as any,
                            panelPosition,
                        }),
                    )
                }
            }
            if (panelPosition !== undefined && task) {
                thunkAPI.dispatch(
                    changePanelTask({
                        position: panelPosition,
                        task: {
                            ...task,
                            files: (task?.files
                                ? [...task.files, res]
                                : [file]) as any,
                            panelPosition,
                        } as ITask,
                    }),
                )
            }
            onSuccess && onSuccess()
        } catch (e) {
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

const createTask = createAsyncThunk<
    void,
    {
        projectId: number
        task:{
            name:string,
            priority:PriorityTypes
            status:number
        }
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/getCommentsAsync',
    async ({ projectId, task, onSuccess, onError }, thunkAPI: any) => {
        try {
            await tasksService.createTask(task, projectId)
            onSuccess && onSuccess()
        } catch (e) {
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

export const asyncActions = {
    getTasks,
    getCurrentTask,
    changeTaskDoers,
    changeTaskSupervisor,
    changeTaskFields,
    getComments,
    addTaskFiles,
    createTask
}

export const slice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setTasks, (state, action) => {
            state.count = action.payload.count
            state.next = action.payload.next
            state.previous = action.payload.previous
            state.results = action.payload.results
        })
        builder.addCase(setCurrentTask, (state, action) => {
            state.currentTask = action.payload.currentTask
        })
        builder.addCase(addTask, (state, action) => {
            state.results = [action.payload.task, ...state.results]
            state.count = state.count + 1
        })
        builder.addCase(deleteTask, (state, action) => {
            state.results = state.results.filter(
                (f) => f.slug !== action.payload.slug,
            )
            state.count = state.count - 1
        })
        builder.addCase(updateTask, (state, action) => {
            state.results = state.results.map((task) => {
                if (task.slug === action.payload.slug) {
                    return { ...task, ...action.payload }
                }

                return task
            })

            if (
                action.payload?.id === state?.currentTask?.id &&
                action.payload?.id !== undefined
            ) {
                state.currentTask = state.currentTask
                    ? { ...state.currentTask, ...action.payload }
                    : action.payload
            }
        })
        builder.addCase(setTaskPriority, (state, action) => {
            state.results = state.results.map((t) =>
                t.id === action.payload.id
                    ? { ...t, priority: action.payload.priority }
                    : t,
            )
        })
        builder.addCase(setTaskTitle, (state, action) => {
            state.results = state.results.map((t) =>
                t.slug === action.payload.slug
                    ? { ...t, name: action.payload.title }
                    : t,
            )
        })
        builder.addCase(updateTaskAssigneeList, (state, action) => {
            const doer = {
                user: action.payload.user,
                id: action.payload.assigneeId,
            }
            state.currentTask = {
                ...state.currentTask,
                doers: [doer, ...state.currentTask.doers],
            }
        })
        builder.addCase(deleteTaskAssigneeMember, (state, action) => {
            const filteredMembers = state.currentTask.doers.filter(
                (f) => f.id !== action.payload.assigneeId,
            )
            state.currentTask = { ...state.currentTask, doers: filteredMembers }
        })
        builder.addCase(updateTaskSupervisor, (state, action) => {
            const supervisor = {
                user: action.payload.user,
                id: action.payload.supervisorId,
            }
            state.currentTask = { ...state.currentTask, supervisor }
        })
        builder.addCase(setSubTasks, (state, action) => {
            state.subtasks = action.payload.subTasks
        })
        builder.addCase(addSubtask, (state, action) => {
            state.subtasks = [action.payload.subtask, ...state.subtasks]
        })
        builder.addCase(deleteSubtask, (state, action) => {
            state.subtasks = state.subtasks.filter(
                (f) => f.slug !== action.payload.slug,
            )
        })
        builder.addCase(setComments, (state, action) => {
            state.comments = action.payload.comments
        })
        builder.addCase(addComment, (state, action) => {
            state.comments = [action.payload.comment, ...state.comments]
        })
        builder.addCase(updateTagsList, (state, action) => {
            const tags = state.currentTask?.tags
                ? [...state?.currentTask?.tags, action.payload.tags]
                : (action.payload.tags as ITag[])
            //@ts-ignore //todo - поправить
            state.currentTask = { ...state.currentTask, tags }
        })
        builder.addCase(setStatuses, (state, action) => {
            state.statuses = action.payload.statuses
        })
        builder.addCase(setTaskStatus, (state, action) => {
            state.status = action.payload
        })
    },
})
