import { Skeleton } from 'antd'
import React, { useState } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { toast } from 'react-toastify'
import { IStatus } from '../../app/types/models/tasks'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { statusesServices } from '../../services/statuses-services'
import { tasksService } from '../../services/tasks-services'
import UiButton from '../../shared/ui/button/UiButton'
import { panelsActions } from '../../store/panels'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import PanelsList from './components/panelsList/PanelsList'
import StatusAdditing from './components/status-additing/StatusAdditing'
import { useGetStatuses } from './hooks'
import './panels.css'
import { useSelector } from 'react-redux'
import { selectPanels, selectPanelsData } from '../../store/panels/selectors'
import { selectCurrentProject } from '../../store/currentProject/selectors'

export interface IPanelsObject {
    [key: number | string]: IStatus
}

const Panels = () => {
    const dispatch = useAppDispatch()
    const [currentSlug, setCurrentSlug] = useState('')
    const location = useLocation()
    const { t } = useTranslation()
    const panels = useSelector(selectPanelsData)
    const currentProject = useSelector(selectCurrentProject)
    const { updatePanelsOrder, moveTasksBetweenPanels, updateTasksOrder } =
        panelsActions
    const [isLoading, setIsLoading] = useState(false)

    const { isLoading: isLoadingGetStatuses } = useGetStatuses(currentProject?.id)
    const [addStatusTriggger, setAddStatusTrigger] = useState(0)

    const onDragEnd = (result: DropResult) => {
        const { destination, source, draggableId, type } = result
        if (!destination) {
            return
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return
        }
        //перетаскиваем сами панели
        if (type === 'column') {
            let newPanelsOrder = [...panels.panelsOrder]
            newPanelsOrder.splice(source.index, 1)
            newPanelsOrder.splice(
                destination.index,
                0,
                parseInt(draggableId, 10),
            )
            //todo - временное решение. Если оставить dispatch только внутри then - то остается дергание
            dispatch(updatePanelsOrder({ panelsOrder: newPanelsOrder }))
            statusesServices
                .updateStatus(
                    currentProject?.id,
                    panels.panels[parseInt(draggableId, 10)].id,
                    {
                        position: destination.index + 1,
                        project: currentProject?.id,
                    },
                )
                .then(() => {
                    dispatch(updatePanelsOrder({ panelsOrder: newPanelsOrder }))
                })
                .catch((err) =>
                    toast.error(err.message, { position: 'top-center' }),
                )
        } else {
            //начальная и конечная доски
            const start = panels.panels[parseInt(source.droppableId, 10)]
            const finish = panels.panels[parseInt(destination.droppableId, 10)]

            //перетаскиваем таски в пределах доски
            if (start && finish && start?.id === finish?.id) {
                const newTaskOrder = [...start?.tasksOrder]
                newTaskOrder.splice(source.index, 1)
                newTaskOrder.splice(destination.index, 0, draggableId)

                tasksService
                    .updateTask(
                        { status_position: destination.index + 1 },
                        currentProject?.id,
                        currentSlug,
                    )
                    .catch((err) => errorsHandler(err, t))

                dispatch(
                    updateTasksOrder({
                        position: start.position,
                        tasks: start.tasks,
                        tasksOrder: newTaskOrder,
                    }),
                )
                return
            }

            //перетаскивать таску между разными досками
            const startTaskOrder = [...start?.tasksOrder]
            startTaskOrder.splice(source.index, 1)
            const currentTask = start.tasks.find(
                (f) => f.status_position === draggableId,
            )

            const newStart = {
                ...start,
                tasksOrder: startTaskOrder,
                tasks: [
                    ...start.tasks.filter(
                        (task) => task.status_position !== draggableId,
                    ),
                ],
            }

            const finishTaskOrder = [...finish?.tasksOrder]
            finishTaskOrder.splice(source.index, 0, draggableId)
            const newFinish = {
                ...finish,
                tasksOrder: finishTaskOrder,
                tasks: [...finish.tasks, currentTask],
            }

            tasksService
                .updateTask(
                    {
                        status: newFinish.id,
                        status_position: destination.index + 1,
                    },
                    currentProject?.id,
                    currentSlug,
                )
                .catch((err) => errorsHandler(err, t))
            dispatch(
                moveTasksBetweenPanels({
                    startPosition: newStart.position,
                    startPanel: newStart,
                    finishPosition: newFinish.position,
                    finishPanel: newFinish,
                }),
            )
        }
    }

    const showSkeleton = () => {
        return (
            <div className="statuses-skeletons">
                {Array(5)
                    .fill('')
                    .map((_) => (
                        <Skeleton.Input
                            active={true}
                            className="statuses-skeleton-item"
                        />
                    ))}
            </div>
        )
    }

    const showContent = () => {
        if (isLoadingGetStatuses) return showSkeleton()
        else if (isLoading) return showSkeleton()

        return (
            <>
                <div className="statuses__inner">
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable
                            droppableId="all-columns"
                            direction="horizontal"
                            type="column"
                        >
                            {(provided) => (
                                <PanelsList
                                    provided={provided}
                                    setCurrentSlug={setCurrentSlug}
                                    projectId={currentProject?.id}
                                />
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
                <StatusAdditing
                    trigger={addStatusTriggger}
                    setIsLoading={setIsLoading}
                    setStrigger={setAddStatusTrigger}
                />
            </>
        )
    }

    return (
        <div className="statuses">
            <div className="statuses-top">
                <UiButton
                    className="statuses-top-btn"
                    type="primary"
                    disabled={isLoading || isLoadingGetStatuses}
                    onClick={() => setAddStatusTrigger((prev) => prev + 1)}
                >
                    + {t('status')}
                </UiButton>
            </div>

            {showContent()}
        </div>
    )
}

export { Panels }

