import { createAction } from '@reduxjs/toolkit'
import {
    IComment,
    IStatus,
    ISubTask,
    ITag,
    ITask,
} from '../../app/types/models/tasks'
import { PriorityTypes } from '../../app/types/enums'
import { LoadingStatusesType } from '../../app/types/common'
import { IUser } from '../../app/types/models/users'
import { RequestStatusType } from '../../app/types/request'

const setTasks = createAction<{
    count: number
    next: string
    previous: string
    results: ITask[]
}>('tasksActions/setTasks')

const setCurrentTask = createAction<{ currentTask: ITask }>(
    'tasksActions/setCurrentTask',
)

const addTask = createAction<{ task: ITask }>('tasksActions/addTask')

const deleteTask = createAction<{ slug: string }>('tasksActions/deleteTask')

const updateTask = createAction<any>('tasksActions/updateTask')

const setTaskPriority = createAction<{ id: number; priority: PriorityTypes }>(
    'tasksActions/setCurrentTaskPriority',
)

const setTaskTitle = createAction<{ slug: string; title: string }>(
    'tasksActions/setCurrentTaskTitle',
)

const updateTaskAssigneeList = createAction<{
    user:IUser
    assigneeId: number
}>('tasksActions/updateCurrentTaskAssigneeList')

const deleteTaskAssigneeMember = createAction<{ assigneeId: number }>(
    'tasksActions/deleteCurrentTaskAssigneeMember',
)

const updateTaskSupervisor = createAction<{
    user:IUser
    supervisorId: number
}>('tasksActions/updateCurrentTaskSupervisor')

const setSubTasks = createAction<{ subTasks: ISubTask[] }>(
    'tasksActions/setSubTasks',
)

const addSubtask = createAction<{ subtask: ISubTask }>(
    'tasksActions/addSubtask',
)

const deleteSubtask = createAction<{ slug: string }>(
    'tasksActions/deleteSubtask',
)

const setComments = createAction<{ comments: IComment[] }>(
    'tasksActions/setComments',
)

const addComment = createAction<{ comment: IComment }>(
    'tasksActions/addComment',
)
const updateTagsList = createAction<{ tags: ITag[] }>(
    'tasksActions/updateTagsList',
)

const setStatuses = createAction<{ statuses: IStatus[] }>(
    'tasksActions/setStatuses',
)

const setTaskStatus = createAction<RequestStatusType>(
    'tasksActions/setTaskStatus',
)

export {
    addTask,
    setTasks,
    deleteTask,
    updateTask,
    setCurrentTask,
    setTaskPriority,
    setTaskTitle,
    updateTaskAssigneeList,
    deleteTaskAssigneeMember,
    updateTaskSupervisor,
    setSubTasks,
    addSubtask,
    deleteSubtask,
    setComments,
    addComment,
    updateTagsList,
    setStatuses,
    setTaskStatus,
}
