import React, { useMemo } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { ITask } from "../../../../app/types/models/tasks";
import { tasksService } from "../../../../services/tasks-services";
import { getValidText } from '../../../../utils/helpers/getValidText';

const TasksBoard = (
    {
        slug,
        projectId,
    }:{
        slug:string,
        projectId:number
    }
) => {
    const location = useLocation()
    const navigate = useNavigate()

    const parentTaskSlug = ():string => {
        return location.pathname.split('/').at(-1).split('_').slice(0,2).join('_')

    }

    const memoizedTaskName = useMemo(() => {
        if(slug && slug?.length>0){
            return decodeURIComponent(slug?.split('_').slice(0, 2).join('_'))
        }else return ''
        
    }, [slug])


    const onClickChangeCurrentPath = async () => {
        try {
            const taskPath = parentTaskSlug()
            const response = await tasksService.getCurrentTask(projectId, taskPath)
            navigate(`/scheduler/${projectId}/tasks/${response.slug}`)
        } catch (e) {
            console.log(e)
        }

    }

    return (
        <>
            {slug?.split('_')?.length < 3 ? (
                <div> {slug}</div>
            ) : (
                <div>
                    <a onClick={onClickChangeCurrentPath}>
                        {getValidText(memoizedTaskName)}
                    </a>{' > '}
                    <span className="taskEditSpace__slug">
                                {getValidText(decodeURIComponent(slug))}
                            </span>
                </div>
            )}
        </>
    );
};

export default TasksBoard;