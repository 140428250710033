import { DatePicker, Spin, Tabs } from 'antd'
import en_EN from 'antd/es/date-picker/locale/en_US'
import ru_RU from 'antd/es/date-picker/locale/ru_RU'
import moment from 'moment'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Route, Routes, useNavigate } from 'react-router-dom'
import 'simplebar-react/dist/simplebar.min.css'
import { PriorityTypes } from '../../app/types/enums'
import { ITask, ITasksFilterParams } from '../../app/types/models/tasks'
import TasksList from '../../entities/tasksList/TasksList'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { UpIcon } from '../../image_files/icons/UpIcon'
import { tasksService } from '../../services/tasks-services'
import { CreatePanel } from '../../shared/CreatePanel/CreatePanel'
import { Pagination } from '../../shared/Pagination/Pagination'
import { tasksActions, tasksSelectors } from '../../store/tasks'
import {
    selectCurrentTask,
    selectTaskData,
    selectTaskStatus,
} from '../../store/tasks/selectors'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import { TaskEditSpace } from './components/taskEditSpace/TaskEditSpace'

import TabPane from 'antd/lib/tabs/TabPane'
import { DATE_FORMAT, TASKS_LIMIT } from '../../app/constants'
import { PlusIcon } from '../../image_files/icons/PlusIcon'
import { statusesServices } from '../../services/statuses-services'
import UiInput from '../../shared/ui/input/UiInput'
import './tasks.css'
import { selectCurrentOrganization } from '../../store/projects/selectors'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { setCurrentTask } from '../../store/tasks/actions'
import { temlatesActions } from '../../store/templates'

enum TabTypes {
    TASKS = '0',
    TEMPLATES = '1',
}

const Tasks = () => {
    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const tasksStatus = useSelector(selectTaskStatus)
    const [currentTab, setCurrentTab] = useState<TabTypes>(TabTypes.TASKS)
    const [isLoadingCurrentTask, setIsLoadingCurrentTask] = useState(false)
    const { results: tasks, count } = useSelector(selectTaskData)
    // const currentProject?.id = Number(location.pathname.split('/')[2])
    const currentProject = useSelector(selectCurrentProject)
    const currentTask = useSelector(selectCurrentTask)
    const currentOrganization = useSelector(selectCurrentOrganization)
    const { setStatuses } = tasksActions
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [filterParams, setFilterParams] = useState<ITasksFilterParams>({
        ordering: 'created_at',
    })
    const statuses = useSelector(tasksSelectors.selectStatuses) || []

    //хэндлеры
    const handleAddTask = async (
        value: string,
        priorityStatus: PriorityTypes,
    ) => {
        if (currentTab === TabTypes.TASKS) {
        }

        try {
            const targetStatus = statuses.find(
                (s) => s?.name_en === 'No status',
            )
            if (targetStatus && targetStatus?.id !== undefined) {
                const newTask = {
                    name: value.trim(),
                    priority: priorityStatus,
                    status: targetStatus.id,
                }
                await tasksService.createTask(newTask, currentProject?.id)
                await handleChangePagination({ limit: 20, offset: 0 })
            }
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const fetchTasks = () => {
        // const pathname = location.pathname
        // const pathItemsList = pathname.split('/').filter((p) => p !== '')
        // if (
        //     pathItemsList[1] !== undefined &&
        //     Number(pathItemsList[1]) &&
        //     Object.keys(filterParams).length > 0
        // ) {
        //     dispatch(
        //         tasksActions.getTasks({
        //             projectId: currentProject?.id,
        //             params: filterParams,
        //         }),
        //     )
        // }
        dispatch(
            tasksActions.getTasks({
                projectId: currentProject?.id,
                params: filterParams,
            }),
        )
    }

    const searchValueHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setFilterParams({ ...filterParams, name__icontains: e.target.value })
    }

    const sortTasksByAgeHandler = () => {
        if (filterParams?.ordering === 'created_at') {
            setFilterParams({ ...filterParams, ordering: '-created_at' })
        } else {
            setFilterParams({ ...filterParams, ordering: 'created_at' })
        }
    }

    const onChangeRangeDate = (value: any[]) => {
        if (Array.isArray(value)) {
            const startValue = moment(new Date(value[0])).format(DATE_FORMAT)
            const endValue = moment(new Date(value[1])).format(DATE_FORMAT)
            setFilterParams({
                ...filterParams,
                created_at__range: `${startValue},${endValue}`,
            })
        } else {
            setFilterParams({ ...filterParams, created_at__range: `` })
        }
    }

    const handleChangePagination = async (params: {
        limit: number
        offset: number
    }) => {
        setFilterParams((prev) => ({ ...prev, ...params }))
    }

    const getTasksByUrl = () => {
        // const pathname = location.pathname
        // const pathItemsList = pathname.split('/').filter((p) => p !== '')
        // if (pathItemsList[0] === 'scheduler' && pathItemsList[2] === 'tasks') {
        //     fetchTasks()
        // }
        // fetchTasks()
    }

    const fetchTemplates = () => {
        dispatch(
            temlatesActions.getTemplates({
                projectId: currentProject?.id,
                params: filterParams,
            }),
        )
    }

    const handleChangeCurrentTab = (tab: TabTypes) => {
        setCurrentTab(tab)

        if (tab === TabTypes.TEMPLATES) {
            navigate(
                `/scheduler/${currentOrganization}/${currentProject?.id}/tasks/templates`,
            )
            dispatch(setCurrentTask({ currentTask: null }))
        } else {
            navigate(
                `/scheduler/${currentOrganization}/${currentProject?.id}/tasks/items`,
            )
        }
    }

    useEffect(() => {
        if (currentTab === TabTypes.TASKS) {
            fetchTasks()
        } else {
            fetchTemplates()
        }
    }, [filterParams, currentProject?.id, currentOrganization, currentTab])

    useEffect(() => {
        if (currentProject?.id !== undefined) {
            statusesServices
                .getStatuses(currentProject?.id)
                .then((res) => {
                    dispatch(setStatuses({ statuses: res }))
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [currentProject?.id])

    useEffect(() => {
        return () => {
            dispatch(setCurrentTask({ currentTask: null }))
        }
    }, [])

    const showEditSpace = () => {
        // if (isLoadingCurrentTask) {
        //     return (
        //         <div className="task-space-loading">
        //             <Spin size="large" />
        //         </div>
        //     )
        // } else if (!tasks || tasks?.length === 0) {
        //     return <></>
        // } else {
        //     return (

        //     )
        // }
        if (currentTask?.slug) {
            return <TaskEditSpace />
        }
    }

    return (
        <div className="scheduler__tasks">
            <div className="pt-10">
                <div className="pos-relative">
                    {
                        <CreatePanel
                            addTask={handleAddTask}
                            prioritySelect={true}
                            className="scheduler__create-panel"
                            panelIcon={
                                <div className="font-14-normal row-flex-5">
                                    <div className="add-entity-icon-wrap">
                                        <PlusIcon circle={false} />
                                    </div>
                                    {/* <span>{t('task')}</span> */}
                                </div>
                            }
                            placeholder={t('input-task-name')}
                        />
                    }

                    <Tabs
                        defaultActiveKey="0"
                        onChange={handleChangeCurrentTab}
                    >
                        <TabPane
                            key="0"
                            tab={
                                <div className="font-14-normal">
                                    {t('tasks')}
                                </div>
                            }
                        >
                            {tasks && tasks.length > 0 ? (
                                <>
                                    <div>
                                        <div
                                            className={
                                                'scheduler__tasks-filter-search'
                                            }
                                        >
                                            <UiInput
                                                placeholder={t(
                                                    'input-task-name',
                                                )}
                                                onChange={searchValueHandler}
                                            />
                                        </div>
                                        <div
                                            className={
                                                'scheduler__tasks-sort-items'
                                            }
                                        >
                                            <DatePicker.RangePicker
                                                allowClear
                                                format={DATE_FORMAT}
                                                className="datePicker-item"
                                                locale={
                                                    i18n.language === 'ru'
                                                        ? ru_RU
                                                        : en_EN
                                                }
                                                onChange={onChangeRangeDate}
                                            />
                                            <div
                                                className="font-14-normal scheduler__tasks-sort-age"
                                                onClick={sortTasksByAgeHandler}
                                            >
                                                <span>
                                                    {filterParams.ordering ===
                                                    'created_at'
                                                        ? t(
                                                              'new-plural',
                                                          ).toString()
                                                        : t(
                                                              'old-plural',
                                                          ).toString()}
                                                </span>
                                                <UpIcon
                                                    className={
                                                        filterParams.ordering ===
                                                        '-created_at'
                                                            ? 'scheduler__tasks-sort-age--down'
                                                            : ''
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {currentTab === TabTypes.TASKS && (
                                        <TasksList />
                                    )}

                                    <Pagination
                                        count={count}
                                        limit={TASKS_LIMIT}
                                        onChange={handleChangePagination}
                                    />
                                </>
                            ) : (
                                tasksStatus !== 'loading' && (
                                    <div className="font-14-normal scheduler__absent-tasks-message">
                                        {t('tasks-absent-message')}
                                    </div>
                                )
                            )}
                        </TabPane>
                        <TabPane
                            key="1"
                            tab={
                                <div className="font-14-normal">
                                    {t('templates')}
                                </div>
                            }
                        >
                            no data yet...
                        </TabPane>
                    </Tabs>
                </div>
            </div>
            {showEditSpace()}
            {/* {isOpenModal && (
                <EditTaskModal
                    isOpen={isOpenModal}
                    closeModal={() => setIsOpenModal(false)}
                />
            )} */}
        </div>
    )
}

export default Tasks
