import React, { useEffect, useState } from 'react'
import PopoverMenu from '../../../../shared/popoverMenu/PopoverMenu'
import { ISubTask, ITask } from '../../../../app/types/models/tasks'
import UiInput from '../../../../shared/ui/input/UiInput'
import { getValidText } from '../../../../utils/helpers/getValidText'
import { IPopoverMenuItem } from '../../../../app/types/common'
import { useTranslation } from 'react-i18next'
import { OpenInNewTabIcon } from '../../../../image_files/icons/OpenInNewTabIcon'
import { goToNewTab } from '../../../../utils/go-to-new-tab'
import { CopyDataIcon } from '../../../../image_files/icons/CopyDataIcon'
import { showNotify } from '../../../../utils/helpers/showNotice'
import { EditIcon } from '../../../../image_files/icons/EditIcon'
import { DeleteIcon } from '../../../../image_files/icons/DeleteIcon'
import { TaskDeleteModal } from '../../../../shared/taskDeleteModal/TaskDeleteModal'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { tasksActions } from '../../../../store/tasks'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../../../store/currentProject/selectors'

const SubTask = ({
    subtask,
    parentTask,
    changeTitle,
}: {
    subtask: ISubTask,
    parentTask:ITask,
    changeTitle: (value: string, slug: string) => void,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const copyUrl = window.location.href
    const currentProject = useSelector(selectCurrentProject)
    const [value, setValue] = useState(subtask?.name ?? '')
    const [editMode, setEditMode] = useState(false)
    const [closeMenuTrigger, setCloseMenuTrigger] = useState(0)
    const [openModal, setOpenModal] = useState(false)

    const showModal = async () => {
        dispatch(
            tasksActions.removeSubtask({
                projectId: currentProject?.id,
                parentTaskSlug:parentTask?.slug,
                slug: subtask?.slug,
                onSuccess: () => {
                    showNotify(t('task-deleted-successfully'), {
                        type: 'success',
                    })
                    setOpenModal(false)
                },
            }),
        )
    }

    const menuItems: IPopoverMenuItem[] = [
        {
            id: 1,
            title: t('open-in-new-tab'),
            icon: <OpenInNewTabIcon />,
            onClick: () => {
                goToNewTab(`${subtask?.slug}`)
            },
        },
        {
            id: 2,
            title: t('copy-link'),
            icon: <CopyDataIcon />,
            onClick: () => {
                navigator.clipboard
                    .writeText(`${copyUrl}/${subtask.slug}`)
                    .then(() => {
                        showNotify(t('link-copied'))
                        setCloseMenuTrigger((prev) => prev + 1)
                    })
            },
        },
        {
            id: 3,
            title: t('edit-task'),
            icon: <EditIcon />,
            onClick: () => {
                setCloseMenuTrigger((prev) => prev + 1)
                setEditMode(true)
            },
        },
        {
            id: 4,
            title: t('delete-task'),
            icon: <DeleteIcon />,
            onClick: () => setOpenModal(true),
        },
    ]

    useEffect(() => {
        setValue(subtask?.name)
    }, [subtask?.name])

    return (
        <li key={subtask?.id} className="flex-c-sb subtask-item">
            {editMode ? (
                <UiInput
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onBlur={() => {
                        setEditMode(false)
                        if (value) {
                            changeTitle(value, subtask?.slug)
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            if (value) {
                                changeTitle(value, subtask?.slug)
                                setEditMode(false)
                            }
                        }
                    }}
                />
            ) : (
                <div className="font-14-normal">
                    {getValidText(subtask?.name)}
                </div>
            )}
            <PopoverMenu items={menuItems} closeTrigger={closeMenuTrigger} />
            <TaskDeleteModal
                openModal={openModal}
                showModal={showModal}
                hideModal={() => setOpenModal(false)}
                title={t('delition-subtask')}
                confirmText={t('ready-to-delete-value-question', {
                    value: t('a-subtask'),
                })}
            />
        </li>
    )
}

export default SubTask
