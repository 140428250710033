import { Skeleton } from 'antd'
import React, { memo, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectStatus } from '../../store/app/selectors'
import { tasksSelectors } from '../../store/tasks'
import { Task } from '../Task/Task'
import { selectCurrentTask, selectTaskData, selectTaskStatus } from '../../store/tasks/selectors'
import { ITask } from '../../app/types/models/tasks'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { setCurrentTask } from '../../store/tasks/actions'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { selectCurrentOrganization } from '../../store/projects/selectors'

const TasksList = () => {
    const navigate = useNavigate()
    const status = useSelector(selectTaskStatus)
    const dispatch = useAppDispatch()
    const currentProject = useSelector(selectCurrentProject)
    const currentOrganization = useSelector(selectCurrentOrganization)
    const tasksList = useSelector(tasksSelectors.selectTasks)
    const { results: tasks} = useSelector(selectTaskData)
    const currentTask = useSelector(selectCurrentTask)
    const handleGetTask = (task: ITask) => {
        navigate(`/scheduler/${currentOrganization}/${currentProject?.id}1/tasks/items/${task?.slug}`)
        dispatch(setCurrentTask({currentTask:task}))
    }

    const taksItems = useMemo(
        () =>
            tasksList.map((task) => {
                return (
                        <Task task={task} onClick={()=>handleGetTask(task)} />
                )
            }),
        [tasksList],
    )

    useEffect(()=>{
        if(currentTask===null && tasks.length>0 && currentOrganization && currentProject){
            navigate(`/scheduler/${currentOrganization}/${currentProject?.id}/tasks/items/${tasks[0]?.slug}`)
            dispatch(setCurrentTask({currentTask:tasks[0]}))
        }
    },[])

    return (
        <div className="mt-10 br-default bg-white">
            {status==='loading' ? (
                <div className="scheduler__tasks-skeleton-wrapper">
                    <TaskSkeletonItem />
                    <TaskSkeletonItem />
                    <TaskSkeletonItem />
                    <TaskSkeletonItem />
                </div>
            ) : (
                <div className="scheduler__tasks-container">{taksItems}</div>
            )}
        </div>
    )
}

const TaskSkeletonItem = () => {
    return (
        <Skeleton.Input
            active={true}
            className="scheduler__tasks-skeleton-item"
        />
    )
}

export default memo(TasksList)
