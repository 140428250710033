import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { tasksService } from '../../services/tasks-services'
import { setCurrentTask, updateTask } from './actions'
import { handleAsyncServerNetworkError } from '../../utils/helpers/errors/handleAsyncError'
import { changePanelTask } from '../panels/actions'
import { ITask } from '../../app/types/models/tasks'

const addFileToTask = createAsyncThunk<
    void,
    {
        file:any,
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/addFileToTaskAsync',
    async ({ file, onSuccess, onError }, thunkAPI: any) => {
        try {
            const currentTask = thunkAPI.getState()?.tasks?.currentTask

            const fmData = new FormData()
            fmData.append('file', file)

            const res = await tasksService.addTaskFile(
                currentTask?.project,
                currentTask?.slug,
                file,
            )

            const files = currentTask?.files
            ? [...currentTask.files, res]
            : [file]

            thunkAPI.dispatch(
                setCurrentTask({
                    currentTask: { ...currentTask, files },
                }),
            )
            onSuccess && onSuccess(res)
        } catch (e) {
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)
const deleteTaskFile = createAsyncThunk<
    void,
    {
        slug: string
        fileId:number
        projectId:number
        task?:ITask,
        panelPosition?:number
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/deleteTaskFileAsync',
    async ({ slug,fileId,task,panelPosition,projectId, onSuccess, onError }, thunkAPI: any) => {
        try {
            const tasks = thunkAPI.getState().tasks.results
            await tasksService.deleteTaskFile(projectId, slug, fileId)
            if(tasks){
                const targetTask = tasks.find(el=>el?.slug===slug)
                if(targetTask){
                    thunkAPI.dispatch(
                        updateTask({
                            slug,
                            files: targetTask?.files
                                ? targetTask.files?.filter((el) => el?.id !== fileId)
                                : [],
                            panelPosition,
                        }),
                    )
                }
            }
            if (panelPosition !== undefined && task) {
                thunkAPI.dispatch(
                    changePanelTask({
                        position: panelPosition,
                        task: {
                            ...task,
                            files: task?.files
                                ? task.files?.filter((el) => el?.id !== fileId)
                                : [],
                            panelPosition,
                        } as ITask,
                    }),
                )
            }
            onSuccess && onSuccess()
        } catch (e) {
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

export const asyncTasksFilesActions = {
    addFileToTask,deleteTaskFile
}
