import { AxiosResponse } from 'axios'
import {
    IComment,
    IFile,
    ITask,
    ITasksFilterParams,
} from '../app/types/models/tasks'
import { ACCESS_TOKEN } from '../utils/constants'
import { PriorityTypes } from '../app/types/enums'
import { instance } from '../app/api'
import { IUser } from '../app/types/models/users'

export const tasksService = {
    getTasks: async (id: number, params?: ITasksFilterParams) => {
        return await instance
            .get<
                any,
                AxiosResponse<{
                    count: number
                    next: string
                    previous: string
                    results: ITask[]
                }>
            >(`projects/${id}/tasks/task/`, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
                params,
            })
            .then((res) => res.data)
    },
    tasksMultipleUpdate: async (id, params) => {
        return await instance.patch<undefined, AxiosResponse<ITask[]>>(
            `projects/${id}/tasks/multiple-update/`,
            { ...params },
        ).then(res=>res.data)
    },
    getTasksSupervisorsList: async (projectId, taskId) => {
        return await instance.get(
            `projects/${projectId}/tasks/task/${taskId}/supervisor/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },
    createTask: async (
        data: {
            name: string
            priority: PriorityTypes
            due_date_start?: string
            due_date_end?: string
            status?:number
        },
        id: number,
    ) => {
        return await instance
            .post<any, AxiosResponse<ITask>>(
                `projects/${id}/tasks/task/`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    updateTask: async (data, id, slug) => {
        return await instance
            .patch<any, AxiosResponse<ITask>>(
                `projects/${id}/tasks/task/${slug}/`,
                data,
            )
            .then((res) => res.data)
    },
    getCurrentTask: async (projectId: number, slug: string) => {
        return await instance
            .get<undefined, AxiosResponse<ITask>>(
                `projects/${projectId}/tasks/task/${slug}`,
            )
            .then((res) => res.data)
    },
    addTaskSuperVisor: async (projectId, taskSlug, params) => {
        return await instance
            .post<
                undefined,
                AxiosResponse<{
                    id: number
                    project: number
                    task: number
                    user: number
                }>
            >(
                `projects/${projectId}/tasks/task/${taskSlug}/supervisor/`,
                {
                    ...params,
                },
            )
            .then((res) => res.data)
    },
    deleteTaskSuperVisor: async (projectId, taskSlug, supervisorId) => {
        return await instance.delete(
            `projects/${projectId}/tasks/task/${taskSlug}/supervisor/${supervisorId}`,
        )
    },
    getTaskAssigneeList: async (projectId, taskId) => {
        return await instance.get(
            `projects/${projectId}/tasks/task/${taskId}/doer/`,
        )
    },
    addTaskDoer: async (projectId, taskSlug, params) => {
        return await instance
            .post<
                undefined,
                AxiosResponse<{
                    id: number
                    project: number
                    task: number
                    user: number
                }>
            >(
                `projects/${projectId}/tasks/task/${taskSlug}/doer/`,
                {
                    ...params,
                },
            )
            .then((data) => data.data)
    },
    deleteTaskAssignee: async (projectId, taskSlug, assigneeId) => {
        return await instance.delete(
            `projects/${projectId}/tasks/task/${taskSlug}/doer/${assigneeId}`,
        )
    },
    createSubTask: async (params, id) => {
        return await instance.post(
            `projects/${id}/tasks/task/`,
            { ...params },
        ).then(res=>res.data)
    },
    updateSubTask: async (params, id, slug) => {
        return await instance.patch(
            `projects/${id}/tasks/task/${slug}/`,
            { ...params },
        ).then(res=>res.data)
    },
    deleteTask: async (projectId: number, taskSlug: string) => {
        return await instance.delete(
            `projects/${projectId}/tasks/task/${taskSlug}/`,
        )
    },
    addTaskFile: async (projectId: number, slug: string, file: any) => {
        return await instance
            .post<undefined, AxiosResponse<IFile>>(
                `projects/${projectId}/tasks/task/${slug}/file/`,
                {
                    file,
                },
                {
                    headers: {
                        'content-type': 'multipart/form-data',
                    },
                },
            )
            .then((res) => res.data)
    },
    deleteTaskFile: async (projectId: number, slug: string, fileId: number) => {
        return await instance.delete(
            `projects/${projectId}/tasks/task/${slug}/file/${fileId}/`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },
    getTaskComments: async (projectId: number, taskSlug: string) => {
        return await instance
            .get<undefined, AxiosResponse<IComment[]>>(
                `projects/${projectId}/tasks/task/${taskSlug}/comment/`,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
    addTaskComment: async (projectId: number, taskSlug: string, data: any) => {
        return await instance
            .post<undefined, AxiosResponse<IComment>>(
                `projects/${projectId}/tasks/task/${taskSlug}/comment/`,
                { ...data },
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem(ACCESS_TOKEN),
                        )}`,
                    },
                },
            )
            .then((res) => res.data)
    },
}
