import { DatePicker, Skeleton } from 'antd'
import en_EN from 'antd/es/date-picker/locale/en_US'
import ru_RU from 'antd/es/date-picker/locale/ru_RU'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DATE_FORMAT, DATE_FORMAT_TO_SERVER } from '../../../../app/constants'
import { ISelectOption } from '../../../../app/types/common'
import { PriorityTypes } from '../../../../app/types/enums'
import { ITag, ITask } from '../../../../app/types/models/tasks'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { ClockIcon } from '../../../../image_files/icons/ClockIcon'
import { LightIcon } from '../../../../image_files/icons/LightIcon'
import MemberIcon from '../../../../image_files/icons/MemberIcon'
import { PriorityIcon } from '../../../../image_files/icons/PriorityIcon'
import { StatusIcon } from '../../../../image_files/icons/StatusIcon'
import { TagsIcon } from '../../../../image_files/icons/TagsIcon'
import { tasksService } from '../../../../services/tasks-services'
import { MembersItem } from '../../../../shared/MembersItem/MembersItem'
import { PrioritySelect } from '../../../../shared/PrioritySelect/PrioritySelect'
import { TagsDropdown } from '../../../../shared/TagsDropdown/TagsDropdown'
import UiInput from '../../../../shared/ui/input/UiInput'
import UISelect from '../../../../shared/ui/select/UlSelect'
import { selectStatus } from '../../../../store/app/selectors'
import { selectCurrentProject } from '../../../../store/currentProject/selectors'
import { membersSelectors } from '../../../../store/members'
import { tasksActions, tasksSelectors } from '../../../../store/tasks'
import { updateTask } from '../../../../store/tasks/actions'
import { selectCurrentTask } from '../../../../store/tasks/selectors'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { getValidText } from '../../../../utils/helpers/getValidText'
import { showNotify } from '../../../../utils/helpers/showNotice'
import './taskDetails.css'

const TaskDetails = () => {
    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation()
    const task = useSelector(selectCurrentTask)
    const status = useSelector(selectStatus)
    const { id: projectId } = useSelector(selectCurrentProject)
    const [statusOptions, setStatusesOptions] = useState<ISelectOption[]>([])

    const [membersOptions, setMembersOptions] = useState<ISelectOption[]>([])

    const [fieldsData, setFieldsData] = useState({
        name: '',
        slug: '',
        priority: 'low',
        status: undefined as any,
        due_date_start: '',
        due_date_end: '',
        tags: [] as number[],
        supervisor: 0 as string | number,
        doers: [] as number[],
    })

    //selectors
    const members = useSelector(membersSelectors.selectMembers)
    const statuses = useSelector(tasksSelectors.selectStatuses)

    //handlers
    const selectPriorityHandler = (priority: PriorityTypes) => {
        dispatch(
            tasksActions.changeTaskFields({
                data: { priority },
                task,
                onSuccess: () => showNotify(t('priority-changed-successfully')),
                onError: (e) => errorsHandler(e, t),
            }),
        )
    }

    const selectStatusHandler = async (status: number) => {
        dispatch(
            tasksActions.changeTaskFields({
                data: { status },
                task,
                onSuccess: () => showNotify(t('status-changed-successfully')),
                onError: (e) => errorsHandler(e, t),
            }),
        )
    }

    const handleChangeRate = (value: any[]) => {
        if (Array.isArray(value)) {
            const due_date_start = moment(new Date(value[0])).format(
                DATE_FORMAT_TO_SERVER,
            )
            const due_date_end = moment(new Date(value[1])).format(
                DATE_FORMAT_TO_SERVER,
            )

            tasksService
                .updateTask(
                    { due_date_start, due_date_end },
                    projectId,
                    task?.slug,
                )
                .then((res) => {
                    dispatch(updateTask(res))
                    showNotify(t('initial-time-changed-successfully'))
                })
                .catch((e) => {
                    errorsHandler(e, t)
                })
        }
    }

    const getMembersOptions = () => {
        if (members && members?.length > 0) {
            setMembersOptions(
                members.map((m) => ({
                    label: (
                        <MembersItem
                            member={m}
                            containerClassName="roadmap-filter-member-select-option"
                        />
                    ),
                    value: m?.user?.id,
                })),
            )
        }
    }

    const changeSupervisor = (id?: string) => {
        dispatch(
            tasksActions.changeTaskSupervisor({
                projectId,
                id,
                task,
                onSuccess: () => showNotify(t('task-updated-successfully')),
                onError: (e) => errorsHandler(e, t),
            }),
        )
    }

    const changeDoers = async (idList?: number[]) => {
        dispatch(
            tasksActions.changeTaskDoers({
                idList,
                projectId,
                task,
                onSuccess: () => showNotify(t('task-updated-successfully')),
                onError: (e) => errorsHandler(e, t),
            }),
        )
    }

    const getValidStatusValue = (task: ITask) => {
        const validId =
            typeof task?.status !== 'number' ? task?.status?.id : task?.status

        if (validId !== undefined) {
            return validId
        } else {
            if (statusOptions && statusOptions.length > 0) {
                return statusOptions[0]?.value
            } else return null
        }
    }

    const getValidTagsOptions = (tags: ITag[] | undefined): number[] => {
        if (tags && tags.length > 0) {
            return tags.map((el) => {
                if (typeof el !== 'number') {
                    return el?.id
                } else return el
            })
        } else return []
    }

    useEffect(() => {
        if (statuses && statuses.length > 0) {
            setStatusesOptions(
                statuses?.map((el) => ({
                    label: i18n.language === 'en' ? el?.name_en : el?.name_ru,
                    value: el?.id,
                })),
            )
        }
    }, [statuses, i18n])

    useEffect(() => {
        getMembersOptions()
    }, [members])

    useEffect(() => {
        if (task !== undefined) {
            setFieldsData((prev) => ({
                ...prev,
                due_date_start: getValidText(task?.due_date_start),
                due_date_end: getValidText(task?.due_date_end),
                tags: getValidTagsOptions(task?.tags),
                doers:
                    task?.doers && task?.doers.length > 0
                        ? task?.doers.map((el) => el?.user?.id)
                        : undefined,
                supervisor: task?.supervisor?.user?.id,
            }))
        }
    }, [task, statusOptions])

    if (status === 'loading') {
        return (
            <div className="task-details">
                {Array(6)
                    .fill('')
                    .map((_) => (
                        <TaskDetailsSkeleton />
                    ))}
            </div>
        )
    }

    return (
        <div className="task-details">
            <PrioritySelect
                label={
                    <div className="row-flex-5 mb-5">
                        <PriorityIcon />
                        <span className="font-14-normal">{t('priority')}</span>
                    </div>
                }
                onChange={selectPriorityHandler}
                currentPriority={task?.priority}
            />
            <UiInput
                label={
                    <div className="row-flex-5 mb-5">
                        <LightIcon />
                        <span className="font-14-normal">{t('creator')}</span>
                    </div>
                }
                value={task?.creator?.full_name}
                disabled
            />
            <UISelect
                label={
                    <div className="row-flex-5 mb-5">
                        <StatusIcon />
                        <span className="font-14-normal">{t('status')}</span>
                    </div>
                }
                options={statusOptions}
                value={getValidStatusValue(task)}
                onChange={selectStatusHandler}
            />
            <UISelect
                label={
                    <div className="row-flex-5 mb-5">
                        <MemberIcon />
                        <span className="font-14-normal">
                            {t('supervisor')}
                        </span>
                    </div>
                }
                options={membersOptions}
                value={fieldsData?.supervisor}
                onChange={changeSupervisor}
            />
            <div>
                <div className="row-flex-5 mb-5">
                    <ClockIcon />
                    <span className="font-14-normal">{t('deadlines')}</span>
                </div>
                <DatePicker.RangePicker
                    allowClear
                    format={DATE_FORMAT}
                    value={[
                        moment(task?.due_date_start),
                        moment(task?.due_date_end),
                    ]}
                    className="datePicker-item"
                    locale={i18n.language === 'ru' ? ru_RU : en_EN}
                    onChange={handleChangeRate}
                />
            </div>
            <UISelect
                label={
                    <div className="row-flex-5 mb-5">
                        <MemberIcon />
                        <span className="font-14-normal">{t('assignee')}</span>
                    </div>
                }
                options={membersOptions}
                mode="multiple"
                value={fieldsData?.doers}
                onChange={changeDoers}
            />
            <div>
                <div className="row-flex-5 mb-5">
                    <TagsIcon />
                    <span className="font-14-normal">{t('tags')}</span>
                </div>
                <TagsDropdown
                    projectId={projectId}
                    taskId={task?.id}
                    slug={task?.slug}
                    tags={task?.tags}
                />
            </div>
        </div>
    )
}

const TaskDetailsSkeleton = () => {
    return (
        <div className="task-details-skeleon__wrap">
            <Skeleton.Input
                active
                className="task-details-skeleon task-details-skeleon__label"
            />
            <Skeleton.Input
                active
                className="task-details-skeleon task-details-skeleon__field"
            />
        </div>
    )
}

export default TaskDetails
