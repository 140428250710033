import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { AccordionArrow } from '../../image_files/icons/AccordionArrow'
import PanelMembersIcon from '../../image_files/icons/PanelMembersIcon'
import MembersBlock from '../../shared/membersBlock/MembersBlock'
import UiPopover from '../../shared/ui/popover/UiPopover'
import UiPopoverContent from '../../shared/ui/popover/UiPopoverContent'
import UISelect from '../../shared/ui/select/UlSelect'
import {
    currentProjectActions,
    currentProjectSelectors,
} from '../../store/currentProject'
import { setProject } from '../../store/currentProject/actions'
import { membersSelectors } from '../../store/members'
import { getOrganizations } from '../../store/organization/selectors'
import { projectsActions, projectsSelectors } from '../../store/projects'
import './SchedulerMenu.css'
import SchedulerMenuFeatures from './SchedulerMenuFeatures'
import { useGetMembers } from '../../hooks/use-get-members'

const SchedulerMenu= () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const pathname = window.location.pathname
    const [collapsed, setCollapsed] = useState(false)
    const { refetch: fetchMembers } = useGetMembers()
    const [fieldsData, setFieldsData] = useState({
        organization: undefined as number | string | undefined,
        project: undefined as number | string | undefined,
    })

    const projects = useSelector(projectsSelectors.selectProjects)
    const currentProject = useSelector(
        currentProjectSelectors.selectCurrentProject,
    )
    const organizations = useSelector(getOrganizations) ?? []
    const members = useSelector(membersSelectors.selectMembers)

    const showValidRoute = (item: any) => {
        const arr = pathname.split('/')
        let currentPage = ''
        if (arr && arr.length > 1) {
            currentPage = arr[arr.length - 1]
        }
        return `${item.id}/${currentPage}`
    }

    const getToCorrectPageByLocation = useCallback(() => {
        if (projects?.length > 0) {
            const pathname = location?.pathname
            //если перейти по ссылке на конкретную задачу
            const urlPartsList = pathname.split('/').filter((el) => el !== '')
            if (urlPartsList[0] === 'scheduler' && urlPartsList.length > 3) {
                const targetProject = projects.find(
                    (p) => p?.id === Number(urlPartsList[1]),
                )
                // if (targetProject) {
                //     dispatch(
                //         currentProjectActions.getDetailProject({
                //             id: targetProject?.id,
                //             onSuccess: () => {
                //                 if (urlPartsList.includes('tasks')) {
                //                     navigate(
                //                         `${targetProject?.id}/tasks/${urlPartsList[3]}`,
                //                     )
                //                 } else if (urlPartsList.includes('roadmap')) {
                //                     navigate(`${targetProject?.id}/roadmap`)
                //                 }
                //             },
                //         }),
                //     )
                // }
            } else {
                // dispatch(
                //     currentProjectActions.getDetailProject({
                //         id: projects[0]?.id,
                //         onSuccess: () => {
                //             if (urlPartsList.includes('tasks')) {
                //                 navigate(`${projects[0]?.id}/tasks`)
                //             } else if (urlPartsList.includes('roadmap')) {
                //                 navigate(`${projects[0]?.id}/roadmap`)
                //             }
                //         },
                //     }),
                // )
            }
        }
    }, [projects])

    const handleCollapseStatus = () => {
        setCollapsed(!collapsed)
    }

    const transformSelectedOption = (label) => {
        if (label && label.length > 0) {
            return label[0].toUpperCase()
        } else return ''
    }

    const handleChangeProject = (e) => {
        // dispatch(
        //     currentProjectActions.getDetailProject({
        //         id: e,
        //         onSuccess: (res) => {
        //             if (res) {
        //                 setFieldsData((prev) => ({
        //                     ...prev,
        //                     project: e,
        //                 }))
        //                 navigate(showValidRoute(res))
        //             }
        //         },
        //     }),
        // )
    }

    const handleChangeOrganization = (id: number | string) => {
        setFieldsData((prev) => ({
            ...prev,
            organization: id,
        }))
        dispatch(
            projectsActions.getProjects({
                params: {
                    organization: id,
                },
                onSuccess: (res) => {
                    if (res && res.length > 0) {
                        dispatch(setProject({ project: res[0] }))
                        navigate(`/scheduler/${id}/${res[0]?.id}/tasks`)
                    }
                },
            }),
        )
        
    }

    useEffect(() => {
        // getToCorrectPageByLocation()
    }, [projects])

    useEffect(() => {
        setFieldsData((prev) => ({ ...prev, project: currentProject?.id }))
    }, [currentProject])

    useEffect(() => {
        if (organizations && organizations.length > 0) {
            setFieldsData((prev) => ({
                ...prev,
                organization: organizations[0]?.id,
            }))
        }
    }, [organizations])

    useEffect(() => {
        if (Number(currentProject?.id)) {
            fetchMembers(currentProject?.id)
        }
    }, [currentProject?.id])

    return (
        <div
            className={`scheduler__menu ${
                collapsed ? 'scheduler__menu-collapsed' : ''
            }`}
        >
            <div
                className={`${
                    collapsed ? 'm-0-auto' : ''
                } scheduler-accordion__arrow`}
                onClick={handleCollapseStatus}
            >
                <AccordionArrow
                    style={
                        collapsed
                            ? { transform: 'rotate(90deg)' }
                            : { transform: 'rotate(-90deg)' }
                    }
                    className={'scheduler-accordion__arrowIcon'}
                />
            </div>
            {collapsed ? (
                <UiPopover
                    color="white"
                    placement="bottomLeft"
                    content={
                        <UiPopoverContent
                            handleClickItem={(e) =>
                                handleChangeOrganization(e?.value)
                            }
                        />
                    }
                    defaultValue={organizations[0]?.id}
                    options={organizations.map((el) => ({
                        label: el?.fullName,
                        value: el?.id,
                    }))}
                    transformSelectedOption={transformSelectedOption}
                />
            ) : (
                <div className="mt-10">
                    <UISelect
                        label={t('organization')}
                        value={fieldsData?.organization}
                        options={organizations.map((el) => ({
                            label: el.fullName,
                            value: el?.id,
                        }))}
                        onChange={(e) => handleChangeOrganization(e)}
                    />
                </div>
            )}
            {collapsed ? (
                <UiPopover
                    options={projects.map((el) => ({
                        label: el?.name,
                        value: el?.id,
                    }))}
                    color='white'
                    content={
                        <UiPopoverContent
                            handleClickItem={(e) =>
                                handleChangeProject(e?.value)
                            }
                        />
                    }
                    placement="bottomLeft"
                    defaultValue={currentProject?.id}
                    transformSelectedOption={transformSelectedOption}
                />
            ) : (
                <div className="mt-10">
                    <UISelect
                        label={t('project')}
                        value={fieldsData.project}
                        options={projects.map((el) => ({
                            label: el.name,
                            value: el?.id,
                        }))}
                        onChange={handleChangeProject}
                    />
                </div>
            )}
            {collapsed ? (
                <UiPopover
                    placement="bottomLeft"
                    content={
                        <UiPopoverContent
                            title={
                                <>
                                    {members.map((el, i) => (
                                        <div key={i}>{el?.user?.email}</div>
                                    ))}
                                </>
                            }
                        />
                    }
                    children={
                        <div className="mt-10 cursor-pointer flex-c-c">
                            <PanelMembersIcon className="flex-shrink-0" />
                        </div>
                    }
                />
            ) : (
                <MembersBlock members={members} />
            )}
            <SchedulerMenuFeatures
                collapsed={collapsed}
            />
        </div>
    )
}

export default SchedulerMenu
