import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IStatus, ITask } from '../../app/types/models/tasks'
import {
    addPanel,
    addTasksToPanel,
    changePanelTask,
    deletePanel,
    deletePanelTask,
    editPanelName,
    moveAllTasks,
    moveTasksBetweenPanels,
    setPanels,
    setPanelsWithoutStatus,
    updatePanelsOrder,
    updateTasksOrder,
} from './actions'
import { handleAsyncServerNetworkError } from '../../utils/helpers/errors/handleAsyncError'
import { setAppStatus } from '../app/actions'
import { statusesServices } from '../../services/statuses-services'
import { AxiosError } from 'axios'
import { showNotify } from '../../utils/helpers/showNotice'
import { tasksService } from '../../services/tasks-services'
import { IPanel, SortPanelTasksTypes } from '../../app/types/models/i-panel'

export interface InitialState {
    panels: any
    panelsOrder: null | any[]
    withoutStatusPanel: (IStatus & { tasks?: ITask[] }) | null
}

const initialState: InitialState = {
    panels: [],
    panelsOrder: [],
    withoutStatusPanel: null,
}

const removePanel = createAsyncThunk<
    void,
    {
        projectId: number
        panel: any
        orderList: any[]
        onSuccess?: () => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'panels/removePanelAsync',
    async ({ projectId, panel, orderList, onSuccess, onError }, thunkAPI) => {
        try {
            thunkAPI.dispatch(setAppStatus({ status: 'loading' }))
            statusesServices.deleteStatus(projectId, panel?.id)

            if (
                orderList &&
                orderList.length === 1 &&
                orderList[0] !== 'withoutStatus'
            ) {
                const newPanelsOrder = orderList.filter(
                    (order) => order !== panel.position,
                )
                thunkAPI.dispatch(
                    deletePanel({
                        position: panel?.position,
                        panelsOrder: newPanelsOrder,
                    }),
                )
            }
            onSuccess && onSuccess()
            thunkAPI.dispatch(setAppStatus({ status: 'succeeded' }))
        } catch (e) {
            if (e?.message) {
                showNotify(e?.message, { type: 'error' })
            }
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

const removePanelTask = createAsyncThunk<
    void,
    {
        projectId: number
        task: ITask
        position: number
        onSuccess?: () => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'panels/removePanelTaskAsync',
    async ({ projectId, task, position, onSuccess, onError }, thunkAPI) => {
        try {
            thunkAPI.dispatch(setAppStatus({ status: 'loading' }))

            await tasksService.deleteTask(projectId, task?.slug)
            thunkAPI.dispatch(
                deletePanelTask({
                    position,
                    taskId: task?.id,
                    status_position: task?.status_position,
                }),
            )
            onSuccess && onSuccess()

            thunkAPI.dispatch(setAppStatus({ status: 'succeeded' }))
        } catch (e) {
            if (e?.message) {
                showNotify(e?.message, { type: 'error' })
            }
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

const changeStatusName = createAsyncThunk<
    void,
    {
        projectId: number
        panel: any
        value: string
        onSuccess?: () => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'panels/changeStatusNameAsync',
    async ({ projectId, panel, value, onSuccess, onError }, thunkAPI) => {
        try {
            thunkAPI.dispatch(setAppStatus({ status: 'loading' }))
            const data = {
                name_en: value,
                name_ru: value,
                project: projectId,
            }
            statusesServices.updateStatus(projectId, panel?.id, data)
            thunkAPI.dispatch(
                editPanelName({
                    position: panel?.position,
                    name: value,
                }),
            )

            onSuccess && onSuccess()
            thunkAPI.dispatch(setAppStatus({ status: 'succeeded' }))
        } catch (e) {
            if (e?.message) {
                showNotify(e?.message, { type: 'error' })
            }
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

const multiplePanelTaskUpdate = createAsyncThunk<
    void,
    {
        projectId: number
        currentPanel: any
        targetPanel: any
        tasks: ITask[]
        onSuccess?: () => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'panels/multiplePanelTaskUpdateAsync',
    async (
        { projectId, currentPanel, targetPanel, tasks, onSuccess, onError },
        thunkAPI,
    ) => {
        try {
            thunkAPI.dispatch(setAppStatus({ status: 'loading' }))
            const task_ids = tasks.map((task) => task.id)
            const res = await tasksService.tasksMultipleUpdate(projectId, {
                task_ids,
                status: targetPanel?.id,
            })

            if (res) {
                const tasksOrder = res.map(
                    (task) => `${task.status_position}-${task.id}`,
                )
                const tasks = res.map((task) => ({
                    ...task,
                    status_position: `${task.status_position}-${task.id}`,
                }))

                thunkAPI.dispatch(
                    moveAllTasks({
                        currentPosition: currentPanel?.position,
                        targetPosition: targetPanel.position,
                        tasksOrder,
                        tasks,
                    }),
                )
            }

            onSuccess && onSuccess()
            thunkAPI.dispatch(setAppStatus({ status: 'succeeded' }))
        } catch (e) {
            if (e?.message) {
                showNotify(e?.message, { type: 'error' })
            }
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

const updatePanelTasksOrder = createAsyncThunk<
    void,
    {
        projectId: number
        panel: any
        type: SortPanelTasksTypes
        statusPositionSymbol: boolean
        onSuccess?: () => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'panels/updatePanelTasksOrderAsync',
    async (
        { projectId, panel, type, statusPositionSymbol, onSuccess, onError },
        thunkAPI,
    ) => {
        try {
            thunkAPI.dispatch(setAppStatus({ status: 'loading' }))

            let param = ''

            switch (type) {
                case SortPanelTasksTypes.BY_CREATION_DATE:
                    // setStatusPositionSymbol(!statusPositionSymbol)
                    param = `${statusPositionSymbol ? '' : '-'}status_position`
                    break
                case SortPanelTasksTypes.BY_LOW_TO_CRITICAL_PRIORITY:
                    param = 'priority'
                    break
                case SortPanelTasksTypes.BY_CRITICAL_TO_LOW_PRIORITY:
                    param = '-priority'
                    break
                case SortPanelTasksTypes.BY_NAME:
                    param = 'name'
                    break
                default:
                    break
            }

            const res = await tasksService.getTasks(projectId, {
                status: panel?.id,
                ordering: param,
            })

            if (res && res?.results && res.results?.length > 0) {
                const tasksOrder = res.results.map(
                    (task) => `${task.status_position}-${task.id}`,
                )
                const tasks = res.results.map((task) => ({
                    ...task,
                    status_position: `${task.status_position}-${task.id}`,
                }))
                thunkAPI.dispatch(
                    updateTasksOrder({
                        position: panel.position,
                        tasksOrder,
                        tasks,
                    }),
                )
            }

            onSuccess && onSuccess()
            thunkAPI.dispatch(setAppStatus({ status: 'succeeded' }))
        } catch (e) {
            if (e?.message) {
                showNotify(e?.message, { type: 'error' })
            }
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

const addNewTaskToPanel = createAsyncThunk<
    void,
    {
        projectId: number
        panel: any
        task: any
        tasks: ITask[]
        onSuccess?: () => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'panels/addNewTaskToPanel',
    async ({ projectId, panel, task, tasks, onSuccess, onError }, thunkAPI) => {
        try {
            thunkAPI.dispatch(setAppStatus({ status: 'loading' }))
            const res = await tasksService.createTask(task, projectId)

            const responseTask = {
                ...res,
                status_position: `${panel.tasksOrder.length + 1}-${res.id}`,
            }

            const tasksList = [...tasks, responseTask]
            const tasksOrder = [
                ...panel.tasksOrder,
                responseTask.status_position,
            ]
            thunkAPI.dispatch(
                addTasksToPanel({
                    position: panel.position,
                    tasks: tasksList,
                    tasksOrder,
                }),
            )

            onSuccess && onSuccess()
            thunkAPI.dispatch(setAppStatus({ status: 'succeeded' }))
        } catch (e) {
            onError && onError(e)
            if (e?.message) {
                showNotify(e?.message, { type: 'error' })
            }
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

const renamePanelTask = createAsyncThunk<
    void,
    {
        projectId: number
        panelPosition: number
        task: ITask
        onSuccess?: () => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'panels/addNewTaskToPanel',
    async (
        { projectId, panelPosition, task, onSuccess, onError },
        thunkAPI,
    ) => {
        try {
            thunkAPI.dispatch(setAppStatus({ status: 'loading' }))
            const res = await tasksService.updateTask(
                { name: task?.name },
                projectId,
                task?.slug,
            )
            thunkAPI.dispatch(
                changePanelTask({
                    position: panelPosition,
                    task: {
                        ...res,
                        status_position: task?.status_position,
                        project: task?.project,
                    },
                }),
            )

            onSuccess && onSuccess()
            thunkAPI.dispatch(setAppStatus({ status: 'succeeded' }))
        } catch (e) {
            onError && onError(e)
            if (e?.message) {
                showNotify(e?.message, { type: 'error' })
            }
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

export const asyncActions = {
    removePanel,
    changeStatusName,
    multiplePanelTaskUpdate,
    updatePanelTasksOrder,
    addNewTaskToPanel,
    removePanelTask,
    renamePanelTask,
}

export const slice = createSlice({
    name: 'panels',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setPanels, (state, action) => {
            state.panels = action.payload.panels
            state.panelsOrder = action.payload.panelsOrder
        })
        builder.addCase(setPanelsWithoutStatus, (state, action) => {
            state.withoutStatusPanel = action.payload.panel
        })
        builder.addCase(updatePanelsOrder, (state, action) => {
            state.panelsOrder = action.payload.panelsOrder
        })
        builder.addCase(addPanel, (state, action) => {
            state.panels = {
                ...state.panels,
                [action.payload.position]: action.payload.panel,
            }
            state.panelsOrder = action.payload.panelsOrder
        })
        builder.addCase(deletePanel, (state, action) => {
            delete state.panels[action.payload.position]
            state.panelsOrder = action.payload.panelsOrder
        })
        builder.addCase(addTasksToPanel, (state, action) => {
            state.panels = {
                ...state.panels,
                [action.payload.position]: {
                    ...state.panels[action.payload.position],
                    tasks: action.payload.tasks,
                    tasksOrder: action.payload.tasksOrder,
                },
            }
        })
        builder.addCase(updateTasksOrder, (state, action) => {
            state.panels = {
                ...state.panels,
                [action.payload.position]: {
                    ...state.panels[action.payload.position],
                    tasks: action.payload.tasks,
                    tasksOrder: action.payload.tasksOrder,
                },
            }
        })
        builder.addCase(editPanelName, (state, action) => {
            state.panels = {
                ...state.panels,
                [action.payload.position]: {
                    ...state.panels[action.payload.position],
                    name: action.payload.name,
                },
            }
        })
        builder.addCase(moveAllTasks, (state, action) => {
            state.panels = {
                ...state.panels,
                [action.payload.currentPosition]: {
                    ...state.panels[action.payload.currentPosition],
                    tasksOrder: [],
                    tasks: [],
                },
                [action.payload.targetPosition]: {
                    ...state.panels[action.payload.targetPosition],
                    tasksOrder: [
                        ...state.panels[action.payload.targetPosition]
                            .tasksOrder,
                        ...action.payload.tasksOrder,
                    ],
                    tasks: [
                        ...state.panels[action.payload.targetPosition].tasks,
                        ...action.payload.tasks,
                    ],
                },
            }
        })
        builder.addCase(deletePanelTask, (state, action) => {
            const filteredTasks = state.panels[
                action.payload.position
            ].tasks.filter((task) => task.id !== action.payload.taskId)
            const filteredTasksOrder = state.panels[
                action.payload.position
            ].tasksOrder.filter(
                (order) => order !== action.payload.status_position,
            )
            state.panels = {
                ...state.panels,
                [action.payload.position]: {
                    ...state.panels[action.payload.position],
                    tasks: filteredTasks,
                    tasksOrder: filteredTasksOrder,
                },
            }
        })
        builder.addCase(changePanelTask, (state, action) => {
            const changedTasks = state.panels[
                action.payload.position
            ].tasks.map((task) =>
                task.id == action.payload.task?.id ? action.payload.task : task,
            )
            state.panels = {
                ...state.panels,
                [action.payload.position]: {
                    ...state.panels[action.payload.position],
                    tasks: changedTasks,
                },
            }
        })
        builder.addCase(moveTasksBetweenPanels, (state, action) => {
            state.panels = {
                ...state.panels,
                [action.payload.startPosition]: action.payload.startPanel,
                [action.payload.finishPosition]: action.payload.finishPanel,
            }
        })
    },
})
