import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import SchedulerMenu from '../../features/scheduler-menu/SchedulerMenu'
import { useGetMembers } from '../../hooks/use-get-members'
import { selectProjects } from '../../store/projects/selectors'
import SchedulerRoutes from './components/scheduler-routes'
import './scheduler.css'
import { SchedulerPagesType } from './types'
import { selectCurrentProject } from '../../store/currentProject/selectors'

export const Scheduler = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const projects = useSelector(selectProjects)
    //селекторы
    const [activePage, setActivePage] = useState<SchedulerPagesType>(
        SchedulerPagesType.tasks,
    )
    // const projectId = Number(location.pathname.split('/')[2])
    const currentProject = useSelector(selectCurrentProject)

    

    // useEffect(() => {
    //     if (activePage === SchedulerPagesType.tasks) {
    //         const pathname = location.pathname
    //         const pathItemsList = pathname.split('/').filter((p) => p !== '')
    //         if (pathItemsList[0] === 'scheduler' && pathItemsList[1] === '*') {
    //             if (currentProject?.id!==undefined && !isNaN(currentProject?.id)) {
    //                 navigate(`${currentProject?.id}/tasks`)
    //             } else {
    //                 if (projects && projects?.length > 0) {
    //                     navigate(`${projects[0]?.id}/tasks`)
    //                 }
    //             }
    //         }
    //     }
    // }, [activePage, projects, currentProject?.id])



    return (
        <div className="scheduler">
            <div className={'scheduler__inner'}>
                <SchedulerMenu />
                <SchedulerRoutes />
            </div>
        </div>
    )
}
