import { AxiosResponse } from "axios"
import { instance } from "../app/api"
import { ITasksFilterParams } from "../app/types/models/tasks"

export const templatesService = {
    getTemplates: async (id: number, params?: ITasksFilterParams) => {
        return await instance
            .get<
                any,
                AxiosResponse<{
                    count: number
                    next: string
                    previous: string
                    results: any[]
                }>
            >(`projects/${id}/tasks/template/`, {
                params,
            })
            .then((res) => res.data)
    },
}