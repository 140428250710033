import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { setTemplates, setTemplatesStatus } from "./actions"
import { ITasksFilterParams } from "../../app/types/models/tasks"
import { AxiosError } from "axios"
import { setAppStatus } from "../app/actions"
import { tasksService } from "../../services/tasks-services"
import { handleAsyncServerNetworkError } from "../../utils/helpers/errors/handleAsyncError"
import { templatesService } from "../../services/templates-service"
import { RequestStatusType } from "../../app/types/request"

export interface InitialState {
    count: number
    next: null | number | string
    previous: null | number | string
    results: any[]
    status: RequestStatusType
}


const initialState: InitialState = {
    count: 0,
    next: null,
    previous: null,
    results: [],
    status:'idle'
}

const getTemplates = createAsyncThunk<
    void,
    {
        projectId: number,
        params?:ITasksFilterParams
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/getTemplatesAsync',
    async ({ projectId,params, onSuccess, onError }, thunkAPI: any) => {
        try {
            thunkAPI.dispatch(setTemplatesStatus('loading'))
            const res = await templatesService.getTemplates(
                projectId,
                params,
            )
            thunkAPI.dispatch(setTemplates(res))
            thunkAPI.dispatch(setTemplatesStatus('succeeded'))
            onSuccess && onSuccess()
        } catch (e) {
            onError && onError(e)
            thunkAPI.dispatch(setTemplatesStatus('failed'))
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

export const asyncActions = {
    getTemplates,
}

export const slice = createSlice({
    name: 'templates',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setTemplates, (state, action) => {
            state.count = action.payload.count
            state.next = action.payload.next
            state.previous = action.payload.previous
            state.results = action.payload.results
        })
        builder.addCase(setTemplatesStatus, (state, action) => {
            state.status = action.payload
        })
    }
})