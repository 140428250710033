import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import PanelRoadmapIcon from '../../image_files/icons/PanelRoadmapIcon'
import PanelStatusesIcon from '../../image_files/icons/PanelStatusesIcon'
import PanelTasksIcon from '../../image_files/icons/PanelTasksIcon'
import TeamIcon from '../../image_files/icons/TeamIcon'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { findCurrentPathFromString } from '../../utils/findCurrentPathFromString'
import { SchedulerPagesType } from '../../widgets/scheduler/types'
import { selectCurrentOrganization } from '../../store/projects/selectors'

interface ISchedulerMenuFeature {
    path: SchedulerPagesType
    title: string
}

const SchedulerMenuFeatures = ({
    collapsed,
}: {
    collapsed: boolean
}) => {
    const { t } = useTranslation()
    const currentProject = useSelector(selectCurrentProject)
    const currentOrganization = useSelector(selectCurrentOrganization)
    const features = [
        { title: t('tasks'), path: 'tasks' },
        { title: t('statuses'), path: 'statuses' },
        // { title: t('scheduler.navbar.equipment'), path: 'equipment' },
        // { title: t('scheduler.navbar.activity'), path: 'activity' },
        { title: t('roadmap'), path: SchedulerPagesType.roadMap },
        { title: t('team'), path: 'team' },
    ]

    const getValidPath = (path:string) =>{
        if(path==='tasks'){
           return `${currentOrganization}/${currentProject?.id}/${path.toLowerCase()}/items`
        }else return `${currentOrganization}/${currentProject?.id}/${path.toLowerCase()}`
    }


    return (
        <ul className="scheduler__menu-features">
            {features.map((f: ISchedulerMenuFeature) => {
                
                return (
                    <NavLink
                        key={f?.path}
                        // style={({ isActive }) => {
                        //     isActive && setActivePage(f.path)
                        //     return {}
                        // }}
                        to={getValidPath(f?.path)}
                    >
                        <SchedulerMenuFeature collapsed={collapsed} item={f} />
                    </NavLink>
                )
            })}
        </ul>
    )
}

const SchedulerMenuFeature = ({
    item,
    collapsed,
}: {
    item: ISchedulerMenuFeature
    collapsed: boolean
}) => {
    const getValidClass = (item: ISchedulerMenuFeature) => {
        const status = findCurrentPathFromString(
            location.pathname,
            item.path.toLowerCase(),
        )
        if (collapsed) {
            return `flex-c-c transition1 scheduler__menu-feature-collapsed ${
                status ? 'scheduler__menu-feature-collapsed-selected' : ''
            }`
        } else {
            return `font-14-normal transition1 cursor-pointer br-default ${
                status ? 'scheduler__menu-feature-selected' : ''
            }`
        }
    }

    const getValidIcon = () => {
        switch (item.path) {
            case SchedulerPagesType.tasks:
                return <PanelTasksIcon className='flex-shrink-0' />
            case SchedulerPagesType.statuses:
                return <PanelStatusesIcon className='flex-shrink-0'/>
            case SchedulerPagesType.roadMap:
                return <PanelRoadmapIcon className='flex-shrink-0'/>
            case SchedulerPagesType.team:
                return <TeamIcon className="flex-shrink-0" />
            default:
                return <></>
        }
    }

    return (
        <li className={getValidClass(item)}>
            {collapsed ? getValidIcon() : <span>{item?.title}</span>}
            
        </li>
    )
}

export default SchedulerMenuFeatures
