import { RequestStatusType } from '../../app/types/request'
import { RootState } from '../store'

export const selectStatus = (state: RootState): RequestStatusType =>
    state.app.status

export const selectIsInitialized = (state: RootState): boolean =>
    state.app.isInitialized

export const selectError = (state: RootState) => state.app.error

export const selectAllState = (state: RootState): RootState => state
