import { createAction } from "@reduxjs/toolkit"
import { RequestStatusType } from "../../app/types/request"

const setTemplates = createAction<{
    count: number
    next: string
    previous: string
    results: any[]
}>('templatesActions/setTemplates')

const setTemplatesStatus = createAction<RequestStatusType>('templatesActions/setTemplatesStatus')

export {
    setTemplates,setTemplatesStatus
}