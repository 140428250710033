import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { getTaskSlugFromUrl } from '../../../../services/get-task-slug-from-url'
import TaskNameEditBlock from '../../../../shared/taskNameEditBlock/TaskNameEditBlock'
import { UITextEditor } from '../../../../shared/text-editor/TextEditor'
import { UiAccordion } from '../../../../shared/ui/accordion/UiAccordion'
import { tasksActions } from '../../../../store/tasks'
import {
    selectCurrentTask,
    selectTasks,
} from '../../../../store/tasks/selectors'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { showNotify } from '../../../../utils/helpers/showNotice'
import { Comment } from '../Comment/Comment'
import './taskEditSpace.css'
import { Skeleton } from 'antd'
import { selectStatus } from '../../../../store/app/selectors'
import FileList from '../TaskFiles/TasksFiles'
import { SubTasks } from '../SubTasks/SubTasks'
import TaskDetails from '../TaskDetails/TaskDetails'
import { selectCurrentProject } from '../../../../store/currentProject/selectors'

export const TaskEditSpace = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const currentProject = useSelector(selectCurrentProject)
    const status = useSelector(selectStatus)
    const tasksList = useSelector(selectTasks)
    const currentTask = useSelector(selectCurrentTask)

    const fetchComments = async () => {
        dispatch(
            tasksActions.getTaskComments({
                slug: currentTask?.slug,
                onError: (e) => {
                    errorsHandler(e, t)
                },
            }),
        )
    }

    const saveComment = async (text: string) => {
        dispatch(
            tasksActions.saveTaskComments({
                text,
                onError: (e) => {
                    errorsHandler(e, t)
                },
            }),
        )
    }

    const addTaskFiles = async (file: any) => {
        dispatch(
            tasksActions.addFileToTask({
                file,
                onSuccess: () => {
                    showNotify(t('file-added-successfully'))
                },
                onError: (e) => errorsHandler(e, t),
            }),
        )
    }

    const deleteTaskFile = async (fileId: number) => {
        dispatch(
            tasksActions.deleteTaskFile({
                fileId,
                slug: currentTask?.slug,
                projectId: currentProject?.id,
                onSuccess: () => showNotify(t('file-deleted-successfully')),
                onError: (e) => errorsHandler(e, t),
            }),
        )
    }

    const updateDescription = (description: string) => {
        if (description !== currentTask?.description) {
            dispatch(
                tasksActions.changeTaskFields({
                    data: { description },
                    task: currentTask,
                    onError: (e) => errorsHandler(e, t),
                }),
            )
        }
    }

    const updateName = (name: string) => {
        if (name !== currentTask?.name) {
            dispatch(
                tasksActions.changeTaskFields({
                    data: { name },
                    task: currentTask,
                    onError: (e) => errorsHandler(e, t),
                }),
            )
        }
    }

    useEffect(() => {
        if (currentTask?.slug !== undefined) {
            dispatch(
                tasksActions.getCurrentTask({
                    slug: currentTask?.slug,
                }),
            )
        }
    }, [currentTask?.id])

    useEffect(() => {
        if (currentTask?.id !== undefined && currentTask?.project) {
            fetchComments()
        }
    }, [currentTask?.project, currentTask?.id])

    if (tasksList.length === 0) {
        return <div className="scheduler__clearSpace"></div>
    }

    return (
        <div className={'scheduler__tasksSettings'}>
            <div className="p-10 br-default scheduler__container">
                <TaskNameEditBlock
                    value={currentTask?.name}
                    projectId={currentProject?.id}
                    slug={currentTask?.slug}
                    onChange={updateName}
                />
                <div className="mt-10">
                    <UiAccordion title={t('details')} activeStatus>
                        <TaskDetails />
                    </UiAccordion>
                    <UiAccordion title={t('description')} activeStatus>
                        {status === 'loading' ? (
                            <Skeleton.Input
                                active
                                className="scheduler__skeleton scheduler__skeleton-description"
                            />
                        ) : (
                            <UITextEditor
                                key={currentTask?.id}
                                initialText={currentTask?.description}
                                handleChange={updateDescription}
                            />
                        )}
                    </UiAccordion>
                    <UiAccordion title={t('subtask')} activeStatus>
                        <SubTasks task={currentTask} />
                    </UiAccordion>
                    <UiAccordion title={t('pin-files')} activeStatus>
                        <FileList
                            files={currentTask?.files}
                            handleSave={addTaskFiles}
                            handleDelete={deleteTaskFile}
                        />
                    </UiAccordion>

                    <UiAccordion activeStatus={true} title={t('comments')}>
                        <Comment
                            onSave={saveComment}
                            comments={currentTask?.comments || []}
                        />
                    </UiAccordion>
                </div>
            </div>
        </div>
    )
}
