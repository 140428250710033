import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { tasksService } from '../../services/tasks-services'
import { handleAsyncServerNetworkError } from '../../utils/helpers/errors/handleAsyncError'
import { addComment, setCurrentTask, updateTask } from './actions'
import { ITask } from '../../app/types/models/tasks'
import { changePanelTask } from '../panels/actions'

const getTaskComments = createAsyncThunk<
    void,
    {
        slug: string
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/getTaskCommentsAsync',
    async ({ slug, onSuccess, onError }, thunkAPI: any) => {
        try {
            const currentTask = thunkAPI.getState()?.tasks?.currentTask
            const res = await tasksService.getTaskComments(
                currentTask?.project,
                currentTask?.slug,
            )

            thunkAPI.dispatch(
                setCurrentTask({
                    currentTask: { ...currentTask, comments: res },
                }),
            )

            thunkAPI.dispatch(
                updateTask({
                    slug,
                    comments: res,
                }),
            )

            onSuccess && onSuccess(res)
        } catch (e) {
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)
const saveTaskComments = createAsyncThunk<
    void,
    {
        text: string
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/getTaskCommentsAsync',
    async ({ text, onSuccess, onError }, thunkAPI: any) => {
        try {
            const currentTask = thunkAPI.getState()?.tasks?.currentTask
            const res = await tasksService.addTaskComment(
                currentTask?.project,
                currentTask?.slug,
                { text },
            )
            thunkAPI.dispatch(
                setCurrentTask({
                    currentTask: {
                        ...currentTask,
                        comments: currentTask?.comments
                            ? [...currentTask.comments, res]
                            : [res],
                    },
                }),
            )
            onSuccess && onSuccess(res)
        } catch (e) {
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

const addNewComment = createAsyncThunk<
    void,
    {
        projectId: number
        slug: string
        value: string
        panelPosition?: number
        task?:ITask
        onSuccess?: (res?: any) => void
        onError?: (e?: AxiosError) => void
    },
    { rejectValue: string }
>(
    'tasks/addCommentAsync',
    async (
        { projectId, slug,task, value, panelPosition, onSuccess, onError },
        thunkAPI: any,
    ) => {
        try {
            const res = await tasksService.addTaskComment(projectId, slug, {
                text: value,
            })
            const tasks = thunkAPI.getState().tasks?.results
            if (tasks && tasks.length > 0) {
                const targetTask = tasks.find((el) => el?.slug === slug)
                if (targetTask) {
                    thunkAPI.dispatch(
                        updateTask({
                            ...targetTask,
                            comments: targetTask.comments
                                ? [...targetTask.comments, res]
                                : [res],
                        }),
                    )
                }
            }
            if (panelPosition!==undefined && task) {
                thunkAPI.dispatch(
                    changePanelTask({
                        position: panelPosition,
                        task: {
                            ...task,
                            comments: task.comments
                                ? [...task.comments, res]
                                : [res],
                        } as ITask,
                    }),
                )
            }
            thunkAPI.dispatch(addComment({ comment: res }))
            onSuccess && onSuccess()
        } catch (e) {
            onError && onError(e)
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

export const asyncTasksCommentsActions = {
    getTaskComments,
    saveTaskComments,
    addNewComment
}
