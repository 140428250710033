import React, { useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { PlusIcon } from '../../../../image_files/icons/PlusIcon'
import { CreatePanel } from '../../../../shared/CreatePanel/CreatePanel'
import { panelsActions } from '../../../../store/panels'
import { showNotify } from '../../../../utils/helpers/showNotice'
import { PanelTask } from '../panelTask/PanelTask'
import './panel.css'
import PanelMenu from './PanelMenu'
import EditTaskModal from '../../../../entities/editTaskModal/EditTaskModal'
import { ITask } from '../../../../app/types/models/tasks'

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'var(--drag-item-phone)' : 'transparent',
})

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? 'var(--drag-item-phone)' : 'transparent',
    ...draggableStyle,
})

const initialModalData = {
    task:null as null | ITask,
    status:false
}

const Panel = ({
    panel,
    tasks,
    index,
    setCurrentSlug,
    currentProjectId,
    panels,
    panelsOrder,
}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const addTask = (value, priorityStatus) => {
        const newTask = {
            name: value.trim(),
            priority: priorityStatus,
            status: panel.id,
        }

        dispatch(
            panelsActions.addNewTaskToPanel({
                panel,
                projectId: currentProjectId,
                task: newTask,
                tasks,
                onSuccess: () => {
                    showNotify(t('task-added-successfully'))
                },
            }),
        )
    }  

    return (
        <div key={`${panel?.position}`}>
            <Draggable
                key={`${panel?.position}`}
                draggableId={`${panel?.position}`}
                index={index}
            >
                {(provided, snapshot) => (
                    <div
                        className="statuses__item"
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                        )}
                    >
                        <div
                            className="row-flex-sb"
                            {...provided.dragHandleProps}
                        >
                            <div className="dotted-text-150 font-14-normal">
                                {panel?.name}
                            </div>
                            <div className="statuses__item-buttons">
                                <CreatePanel
                                    prioritySelect
                                    statusId={panel?.id}
                                    placeholder={t('input-task-name')}
                                    panelIcon={
                                        <div className="add-entity-icon-wrap">
                                            <PlusIcon circle={false} />
                                        </div>
                                    }
                                    addTask={addTask}
                                />
                                <PanelMenu
                                    panel={panel}
                                    panels={panels}
                                    tasks={tasks}
                                    panelsOrder={panelsOrder}
                                />
                            </div>
                        </div>
                        <Droppable
                            key={`${panel?.position}`}
                            type={'task'}
                            droppableId={`${panel?.position}`}
                        >
                            {(provided, snapshot) => (
                                <div
                                    className="statuses__taskList"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(
                                        snapshot.isDraggingOver,
                                    )}
                                >
                                    <div className="statuses__taskList-container">
                                        {tasks?.map((task, index) => (
                                            <PanelTask
                                                task={task}
                                                key={task?.status_position}
                                                taskIndex={index}
                                                setCurrentSlug={setCurrentSlug}
                                                panelPosition={panel?.position}
                                                currentProjectId={
                                                    currentProjectId
                                                }
                                            />
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                </div>
                            )}
                        </Droppable>
                    </div>
                )}
            </Draggable>
        </div>
    )
}

export { Panel }
