import { DatePicker, Modal } from 'antd'
import en_EN from 'antd/es/date-picker/locale/en_US'
import ru_RU from 'antd/es/date-picker/locale/ru_RU'
import moment from 'moment'
import React, { FC, useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DATE_FORMAT, DATE_FORMAT_TO_SERVER } from '../../app/constants'
import { ISelectOption } from '../../app/types/common'
import { PriorityTypes } from '../../app/types/enums'
import { ITag, ITaskFieldsForUpdates } from '../../app/types/models/tasks'
import { RoadmapContext } from '../../features/roadMap/providers'
import useDebounce from '../../hooks/useDebounce'
import { MembersItem } from '../../shared/MembersItem/MembersItem'
import { PrioritySelect } from '../../shared/PrioritySelect/PrioritySelect'
import { TagsDropdown } from '../../shared/TagsDropdown/TagsDropdown'
import { stopEvent } from '../../shared/stopEvent'
import TaskNameEditBlock from '../../shared/taskNameEditBlock/TaskNameEditBlock'
import UiButton from '../../shared/ui/button/UiButton'
import UISelect from '../../shared/ui/select/UlSelect'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { membersSelectors } from '../../store/members'
import { selectPanels } from '../../store/panels/selectors'
import { selectStatuses } from '../../store/tasks/selectors'
import './EditTaskModal.css'
import RoadMapUpdateTaskTabs from './RoadMapUpdateTaskTabs'
import { initialData } from './data'

interface IProps {
    // task?: ITask
    type?: 'task' | 'panel-task' | 'template'
    data: ITaskFieldsForUpdates
    projectId:number
    isOpen: boolean
    className?: string
    panelPosition?: number
    closeModal?: () => void
    onChange?: (data: ITaskFieldsForUpdates) => void
}

const EditTaskModal: FC<IProps> = (props) => {
    const {
        data,
        isOpen,
        type = 'task',
        projectId,
        panelPosition,
        closeModal,
        onChange,
    } = props
    const { t, i18n } = useTranslation()
    const { setTagsOptions } = useContext(RoadmapContext)
    const panels = useSelector(selectPanels)
    const statuses = useSelector(selectStatuses)
    const [statusOptions, setStatusesOptions] = useState<ISelectOption[]>([])
    const [membersOptions, setMembersOptions] = useState<ISelectOption[]>([])
    const members = useSelector(membersSelectors.selectMembers)

    const [fieldsData, setFieldsData] = useState(data)
    const debouncedName = useDebounce(fieldsData.name, 500)

    //change fields
    const handleChangeField = (data: ITaskFieldsForUpdates) => {
        setFieldsData((prev) => ({ ...prev, ...data }))
        onChange && onChange(data)
    }

    const handleChangeRate = (value: any[]) => {
        if (Array.isArray(value)) {
            const due_date_start = moment(new Date(value[0])).format(
                DATE_FORMAT_TO_SERVER,
            )
            const due_date_end = moment(new Date(value[1])).format(
                DATE_FORMAT_TO_SERVER,
            )
            // updateTaskFields({ due_date_start, due_date_end })
            handleChangeField({ due_date_start, due_date_end })
        }
    }

    const changeSupervisor = (id?: number) => {
        id !== undefined && handleChangeField({ supervisor: id })
    }

    const changeDoers = async (idList?: number[]) => {
        idList && handleChangeField({ doers: idList })
    }

    const handleCreateTag = (res) => {
        if (res) {
            setTagsOptions((prev) => [
                ...prev,
                { label: res?.name, value: res?.id },
            ])
        }
    }

    const getMembersOptions = () => {
        if (members && members?.length > 0) {
            setMembersOptions(
                members.map((m) => ({
                    label: (
                        <MembersItem
                            member={m}
                            containerClassName="roadmap-filter-member-select-option"
                        />
                    ),
                    value: m?.user?.id,
                })),
            )
        }
    }

    const getValidTagsOptions = (tags: ITag[] | undefined): number[] => {
        if (tags && tags?.length > 0) {
            return tags.map((el) => {
                if (typeof el !== 'number') {
                    return el?.id
                } else return el
            })
        } else return []
    }

    useEffect(() => {
        if (statuses && statuses?.length > 0 && panelPosition === undefined) {
            setStatusesOptions(
                statuses?.map((el) => ({
                    label: i18n.language === 'en' ? el?.name_en : el?.name_ru,
                    value: el?.id,
                })),
            )
        }
    }, [statuses, i18n])

    useEffect(() => {
        getMembersOptions()
    }, [members])

    useEffect(() => {
        if (debouncedName?.length > 0 && debouncedName !== data?.name) {
            handleChangeField({ name: debouncedName })
        }
    }, [debouncedName])

    useEffect(() => {
        if (type === 'template' && panels) {
            const list: ISelectOption[] = []
            for (let key in panels) {
                const item = panels[key]
                list.push({
                    value: item?.id,
                    label: item?.name,
                })
                setStatusesOptions(list)
            }
        }
    }, [panelPosition, panels])

    console.log('membersOptions', membersOptions)

    return (
        <div className="roadmap-edit-task-block" onClick={stopEvent}>
            {createPortal(
                <Modal
                    footer={null}
                    open={isOpen}
                    className="roadmap-add-task-modal"
                    onCancel={() => {
                        closeModal && closeModal()
                    }}
                >
                    <div>
                        <div className="roadmap-add-task-modal-settings">
                            <TaskNameEditBlock
                                value={fieldsData.name}
                                slug={data?.slug}
                                projectId={projectId}
                                panelPosition={panelPosition}
                                onChange={(name) => {
                                    handleChangeField({ name })
                                }}
                            />
                            <div className="roadmap-add-task-modal-items-wrapper">
                                <div>
                                    <div className="roadmap-filter-field-wrap">
                                        <PrioritySelect
                                            label={t('priority')}
                                            onChange={(e) =>
                                                handleChangeField({
                                                    priority: e,
                                                })
                                            }
                                            currentPriority={
                                                fieldsData.priority as PriorityTypes
                                            }
                                        />
                                    </div>
                                    {['task', 'template'].includes(type) && (
                                        <div className="roadmap-filter-field-wrap">
                                            <UISelect
                                                label={t('status')}
                                                options={statusOptions}
                                                value={fieldsData?.status}
                                                onChange={(e) =>
                                                    handleChangeField({
                                                        status: e,
                                                    })
                                                }
                                            />
                                        </div>
                                    )}
                                    {['task', 'panel-task'].includes(type) && (
                                        <>
                                            <div className="roadmap-filter-field-wrap">
                                                <div className="font-14-normal">
                                                    {t('deadlines')}
                                                </div>
                                                <DatePicker.RangePicker
                                                    allowClear
                                                    format={DATE_FORMAT}
                                                    value={[
                                                        moment(
                                                            fieldsData?.due_date_start,
                                                        ),
                                                        moment(
                                                            fieldsData?.due_date_end,
                                                        ),
                                                    ]}
                                                    className="datePicker-item"
                                                    locale={
                                                        i18n.language === 'ru'
                                                            ? ru_RU
                                                            : en_EN
                                                    }
                                                    onChange={handleChangeRate}
                                                />
                                            </div>
                                            <div className="roadmap-filter-field-wrap">
                                                <div className="font-14-normal">
                                                    {t('tags')}
                                                </div>
                                                <TagsDropdown
                                                    projectId={
                                                        projectId
                                                    }
                                                    taskId={data?.id}
                                                    slug={data?.slug}
                                                    tags={data?.tags}
                                                    createTag={handleCreateTag}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div>
                                    {['task', 'panel-task'].includes(type) && (
                                        <>
                                            <div className="roadmap-add-task-modal-members">
                                                <div className="roadmap-filter-field-wrap">
                                                    <UISelect
                                                        label={t('supervisor')}
                                                        options={membersOptions}
                                                        value={
                                                            fieldsData?.supervisor
                                                        }
                                                        onChange={
                                                            changeSupervisor
                                                        }
                                                    />
                                                </div>
                                                <div className="roadmap-filter-field-wrap">
                                                    <UISelect
                                                        label={t('assignee')}
                                                        options={membersOptions}
                                                        mode="multiple"
                                                        value={
                                                            fieldsData?.doers
                                                        }
                                                        onChange={changeDoers}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <RoadMapUpdateTaskTabs
                            fields={{
                                id: data?.id,
                                comments: data?.comments,
                                slug: data?.slug,
                                project: projectId,
                                subtasks: data?.subtasks,
                                description: fieldsData?.description,
                                files: data?.files,
                            }}
                            type={type}
                            panelPosition={panelPosition}
                            projectId={projectId}
                            onChange={handleChangeField}
                        />
                    </div>
                </Modal>,
                document.body,
            )}
        </div>
    )
}

export default EditTaskModal
